@import "../../theme/mixins";
@import "../../theme/variables";

.reminders {
  position: absolute;
  top: 90px;
  right: 20px;
  z-index: 10000;

  .reminder {
    background-color: $white;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.14);
    border-radius: 4px;
    width: 320px;
    height: 65px;
    opacity: 1;
    margin-bottom: 20px;

    :global(.row) {
      padding: 0px !important;
      height: 65px;
    }

    .content {
      display: flex;
      flex: 1 0;
      align-items: center;
      padding: 10px;

      :global(.grid) {
        padding: 10px !important;
      }

      .icon {
        color: $primaryColor;
        background-color: rgba(104, 90, 206, 0.15);
        border-radius: 20px;
        height: 30px;
        width: 30px;

        :global(.icon) {
          font-weight: 600;
          margin-left: auto;
          margin-right: auto;
          display: block;
          margin-top: 10%;
        }
      }

      p {
        margin-left: 10px;

        > * {
          display: block;
        }
        .title {
          font-size: 16px;
          font-weight: 600;
          color: $black;
          line-height: 27px;
        }
        .time {
          color: $grey;
          line-height: 16px;
          font-size: 13px;
        }
      }
    } // .content

    .buttons {
      padding-left: 0px !important;

      div {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50%;
        cursor: pointer;
        font-size: 13px;
        line-height: 16px;

        &.action {
          border-bottom: 1px solid $lightgrey;
          a {
            color: $black !important;
          }
        }
      }
    }
  } // .reminder
}

.options {
  font-size: 15px;
  line-height: 18px;
  color: $black;

  :global(.item) {
    margin: 10px 0px !important;
    cursor: pointer;

    &:hover {
      background: rgba(104, 90, 206, 0.15);
    }
  }
}
