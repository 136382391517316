@import "../../theme/mixins";
@import "../../theme/variables";

$deleteLabelColor: #db2828;
$pollOptionCheckboxMargin: 10px;
$pollOptionCheckboxSize: 16px;
$pollOptionDotColor: $white;
$pollOptionMarginBottom: 25px;
$pollOptionOffBgColor: #d8d8d8;
$pollOptionOnBgColor: $black;
$pollOptionTransition: 0.2s ease;
$mobileViewHeaderIconColor: #d8d8d8;

$hoverColor: rgba($blue, 0.1);

$linkColor: #757575;

$videoSelectedColor: $white;
$videoBackgroundColor: $black;

.mobile {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.computer {
  flex: 1;
}

.removeButton {
  background: none !important;
  color: #757575 !important;
  font-weight: normal !important;
  padding: 0 !important;

  i {
    font-size: 16px !important;
  }

  &:global(.ui.disabled) {
    background: none !important;
  }
}

.mobile,
.computer {
  height: 100%;

  > div {
    height: 100%;
  }
}

.button {
  @include button();
}

.buttonLink {
  @include buttonLink();
}

.container {
  display: flex;
  flex: 1;
  flex-direction: row;
  height: 100%;
  width: 100%;
}

.divider {
  margin: 0 !important;
}

.header {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  padding: 0px;
  text-align: initial !important;
  border-bottom: 1px solid $lightgrey;

  :global(.ui.icon.button) :global(.icons) {
    margin-right: -5px;
  }
}

.barIcon {
  background-color: transparent !important;
  padding: 4px !important;
  font-size: 14px !important;
  line-height: 16px !important;
  font-weight: 900 !important;
  color: $mobileViewHeaderIconColor !important;
  margin-right: 14px !important;

  &.topMenuVisible {
    font-size: 18px !important;
    margin-right: -10px !important;
  }

  .unreadMessagesNotif {
    background-color: $orange;
    border-radius: 10px;
    border: solid 1px $darkpurple;
    height: 10px;
    position: absolute;
    top: -3px;
    left: 7px;
    width: 10px;
  }
}

.hireWrapper {
  margin: 0;
  flex: initial !important;
}

.loader {
  margin-left: 2px !important;

  &:after,
  &:before {
    width: 0.75rem !important;
    height: 0.75rem !important;
    margin: 0 0 0 -0.375rem !important;
  }
}

.loaderContainer {
  background: transparent !important;
  border: none !important;
  box-shadow: none !important;
  margin: 0 !important;

  > * {
    background: transparent !important;
  }
}

.messageContainerWrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  height: 100%;
  position: relative;
  overflow-x: hidden;

  > :global(.ui.page.dimmer) {
    position: absolute;
  }

  &.hasCall {
    width: 300px;
    max-width: 300px;
    display: none;

    @media (min-width: 991px) {
      display: flex;
    }
  }
}

.welcome {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 30px;
  background-color: #ffffff;
  min-height: 500px;

  @media (min-width: $computerBreakpoint) {
    margin-top: -60px;
  }

  img {
    display: block;
    margin: 0 auto;
    max-width: 100%;
  }

  h2 {
    font-size: 24px;
    font-style: normal;
    font-weight: normal;
    color: #212121;
    line-height: 29px;
    margin-bottom: 8px;
    text-align: center;
  }

  p {
    font-size: 14px;
    line-height: 21px;
    color: #4d4d4d;
    text-align: center;
    margin-bottom: 32px;
  }

  button {
    margin: 20px auto 0 auto;
    display: block;
    font-size: 14px;
    line-height: 21px;
  }

  @media (max-width: 480px) {
    h2 {
      font-size: 18px;
    }

    p {
      font-size: 12px;
      line-height: 16px;
    }
  }
}

.messageWithSidepanel {
  display: flex;
  flex: 1;
  overflow: hidden;
  position: relative;

  .messageContainer {
    display: flex;
    width: 100%;
    flex: 1;
    flex-direction: column;
    padding: 0;

    &.hasCall {
      min-width: 300px;
      max-width: 300px;
      width: 300px;
      flex: 0;
    }
  }

  .sidePanel {
    display: none;
    background: #ffffff;
    box-shadow: 0 2px 4px 0 #e0e0e0;
    margin-bottom: 0;
    overflow-x: hidden;
    position: relative;
    width: 100vw;
    min-height: 100%;

    @media (min-width: 991px) {
      width: 400px;
    }

    .heading {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 16px 16px 0px 16px;

      p,
      h2 {
        color: #212121;
        font-size: 24px;
        line-height: 29px;
        margin: 0;
      }

      .actionButton:global(.ui.button) {
        font-size: 14px;
        line-height: 21px;
        font-weight: normal;
        letter-spacing: -0.0373333px;
        border: 1px solid #d3d3d3 !important;
        color: #4d4d4d !important;
        box-shadow: none !important;
        background-color: $white !important;

        &:hover {
          border: 1px solid $black !important;
          color: $black !important;
        }

        &.close {
          display: none;

          @media (max-width: $tabletBreakpoint - 1) {
            display: inline-block;
          }

          &.hasProjectUrlsClose {
            @media (min-width: 992px) and (max-width: 1135px) {
              display: inline-block;
            }

            @media (max-width: 920px) {
              display: inline-block;
            }
          }
        }

        &.searchButtons {
          padding: 8px 16px;
        }

        @media (max-width: $tabletBreakpoint - 1) {
          font-size: 12px;
          padding: 8px 12px;
        }
      }

      .searchSort {
        :global(.dropdown.icon) {
          display: none;
        }

        :global(.menu) {
          right: 4px;
          left: auto !important;
        }
      }
    }

    .sidePanelClose {
      @include buttonLink($grey, $darkgrey);
      line-height: 52px;
      position: fixed;
      right: 20px;
      z-index: 10;
    }

    .copied {
      font-size: 14px;
      line-height: 1;
      margin: 8px 0 0;
      text-align: center;
    }

    .roomEmail > input {
      color: $primaryColor;
    }

    .section {
      border-top: 1px solid #e0e0e0;

      :global(.content) {
        margin-top: 0;
        margin-bottom: 20px;
      }
    }

    .sectionProjectLinksFormSubmit {
      text-transform: uppercase;
    }

    .sectionMemberAddForm {
      border-bottom: 1px solid #e0e0e0;
      margin: 0 15px 7.5px;
      padding: 5px 0;
    }

    .sectionMemberAddFormHeader {
      align-items: center;
      display: flex;
      font-size: 14px;
      margin: 5px 0 7.5px;
      justify-content: space-between;
    }

    .sectionMemberAddFormHeaderPrimary {
      font-weight: $fontWeightBold;
      text-transform: uppercase;
    }

    .sectionMemberAddFormHeaderSecondary {
      color: #757575;
    }

    .sectionMemberAddFormSubmit {
      display: block;
      margin: 15px 0 8px auto;
      padding: 12px 20px !important;
      width: auto !important;
    }

    .sectionMemberListItem {
      align-items: center;
      border: none;
      box-shadow: none;
      display: flex;
      flex: 1;
      min-height: 40px;
      padding: 7.5px 20px;

      &:link {
        color: $black;
      }

      &:focus,
      &:hover {
        background-color: $hoverColor;

        .sectionMemberListItemActions {
          display: block;
        }
      }

      &:first-of-type {
        margin-top: 2.5px;
      }

      &:last-of-type {
        margin-bottom: -7.5px;
      }

      &.clickable {
        cursor: pointer;
      }

      &.textOnly {
        font-size: 14px;
        min-height: auto;
        font-weight: 600;

        &:hover {
          background-color: inherit;
        }
      }
    }

    .sectionMemberListItemActions {
      display: none;
      font-size: 12px;
      font-weight: $fontWeightBold;
      white-space: nowrap;
    }

    .sectionMemberListItemActionsButton {
      @include buttonLink($grey, $darkgrey);
    }

    .sectionMemberListItemAdd {
      align-items: center;
      background-color: #efefef;
      border-radius: 15px;
      display: flex;
      font-size: 18px;
      height: 30px;
      margin: 0;
      width: 30px;

      &:before {
        flex: 1;
      }
    }

    .sectionMemberListItemLink {
      display: flex;
      align-items: center;
      color: $black;
    }

    .sectionMemberListItemTitle {
      flex: 1 0;
      margin: 0 8px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      i:global(.icon) {
        margin: 0 0 0 0.25rem;
      }
    }

    .sectionMemberMatch {
      cursor: pointer;
      margin: 2px 0;
      overflow: hidden;
      padding: 0 10px;
      text-overflow: ellipsis;
      white-space: nowrap;

      &:first-of-type {
        margin-top: 0;
      }

      &:last-of-type {
        margin-bottom: 0;
      }

      &:hover,
      &.selected {
        background: rgba($blue, 0.1);
      }
    }

    .sectionMemberMatchAvatar {
      display: inline-block;
      margin-right: 5px;
      vertical-align: top !important;

      i {
        line-height: 16px !important;
      }
    }

    .sectionTitle {
      align-items: center;
      display: flex;
    }

    .sectionTitleIcon {
      font-size: 18px;
      line-height: 1;
      margin-right: 12px;
    }

    .sectionTitleText {
      flex: 1 0;
    }

    > h3 {
      background-color: $white;
      border-bottom: 1px solid #e0e0e0;
      color: $primaryColor;
      font-size: 16px;
      font-weight: $fontWeightBold;
      margin: 0;
      overflow: hidden;
      padding: 15px 80px 15px 15px;
      position: fixed;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100vw;
      z-index: 2;

      @media (min-width: 991px) {
        width: 400px;
      }
    }

    > h3 + * {
      margin-top: 57px !important;
    }

    > h4 {
      color: #232323;
      font-size: 14px;
      margin-bottom: 4px;
    }

    form {
      h4 {
        text-transform: uppercase;
        color: $black;
        margin-bottom: 15px;
      }

      .cancel {
        background: transparent;
        float: right;
        font-size: 14px;
        padding: 0;
      }

      input[type="search"] {
        margin: 15px 15px 0 15px;

        + :global(.icon) {
          margin-left: 15px;
          margin-top: 7px;
        }
      }

      :global(.ui.dropdown[role="listbox"]) {
        margin: 0 15px;

        > :global(.text) {
          display: none;
        }
      }

      button[type="submit"] {
        color: #ffffff;
        font-size: 14px;
      }
    }

    :global(.ui.segment) {
      margin-bottom: 0;
      margin-top: 0;
      padding: 15px;
    }

    :global(.ui.accordion) {
      :global(.title) {
        padding: 15px;
      }

      :global(.column.row) {
        padding-left: 12px;
        padding-right: 12px;
      }

      :global(.column.row > .column) {
        padding-left: 4px;
        padding-right: 4px;
      }
    }
  }

  &.visible {
    .sidePanel {
      display: block;
    }

    @media (max-width: 990px) {
      .messageContainer {
        display: none;
      }
    }
  }

  &.visible.fullSize {
    .sidePanel {
      width: 100%;
      width: 100%;
    }

    .messageContainer {
      display: none;
    }
  }
}

.highlightedResult {
  background-color: #c8ebfc;
}

.messageHeaderButtons {
  float: right;

  :global(.icon.button) {
    box-shadow: none;
    margin: 0 10px 0 0;
    padding: 0.5em 0.5em 0.5em;

    &:hover,
    &:active,
    &:focus {
      background: $offwhite !important;
      box-shadow: none;
    }

    &:global(.active) {
      background: $primaryColor !important;
      color: $white;

      &:hover,
      &:active,
      &:focus {
        background: $primaryColor !important;
      }
    }

    &:last-child {
      margin-right: 0;
    }

    i:global(.icon) {
      font-weight: $fontWeightBold;
    }
  }

  :global(.hasNotification) {
    position: relative;

    i::after {
      content: "";
      width: 10px;
      height: 10px;
      border-radius: 5px;
      border: 1px solid #ffffff;
      background-color: #f45f53 !important;
      display: inline-block;
      top: 4px;
      right: 4px;
      position: absolute;
    }
  }

  @media (max-width: 480px) {
    position: absolute;
    right: 10px;
    background: white;
    border-radius: 24px;
    overflow: hidden;
    padding: 3px;
    transition: all 0.3s ease-in-out;
    white-space: nowrap;
    width: 48px;

    &::before {
      content: "\f053";
      color: $grey;
      font-family: "Icons";
      font-size: 12px;
      font-weight: $fontWeightHeavy;
      position: absolute;
      right: 2px;
      top: 50%;
      transform: translateY(-50%);
    }

    :global(.icon.button) {
      display: none;

      &:global(.active) {
        display: inline-block;
      }
    }

    &:focus-within {
      box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.2);
      transition: all 0.1s ease-in-out;
      width: 206px; // 3px<left padding> + 3px<right padding> + (42px * 7)<icons> - 10px<last icon margin>

      &::before {
        display: none;
      }

      :global(.icon.button) {
        display: inline-block;
      }
    }
  }

  div {
    display: inline-block;
  }

  .appLink {
    button {
      &:hover {
        border-radius: 10em;
      }
    }
  }
}

.messageHeaderButtonsDisconnect {
  color: #e57373;
  transform: rotate(-135deg);
}

.messageHeaderTextWrapper {
  display: flex;
  align-items: center;
  flex: 1;
  padding: 8px 15px;

  .dropdown {
    display: inline-block;
    line-height: 1;
    vertical-align: middle;
    white-space: nowrap;
    position: relative !important;
    margin-right: auto;

    .messageHeaderText {
      color: $fontColor;
      font-size: 18px;
      letter-spacing: 0.25px !important;
      line-height: 22px;
      display: flex;
      align-items: center;

      .roomName {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: block;
        max-width: 450px;
        align-items: center;

        .projectName {
          display: none;
        }

        :global(.icon) {
          font-weight: 900;
        }

        @media (max-width: $computerBreakpoint - 1) {
          margin-left: 10px;
          font-size: 14px;
          line-height: 18px;
          color: $white;

          .projectName {
            display: inline;
            align-items: center;

            i {
              margin: 0 2px 0 2px;
            }
          }

          .channelName {
            font-size: 11px;
          }
        }

        @media (min-width: $tabletBreakpoint) and (max-width: $computerBreakpoint) {
          max-width: 400px;
        }

        @media (max-width: $tabletBreakpoint - 1) {
          width: auto;
        }
      }

      @media (max-width: $computerBreakpoint - 1) {
        :global(.large.icons) {
          font-size: 18px !important;
        }
      }
    }
  }

  .messageHeader {
    display: flex;
    align-items: center;

    @media (max-width: $tabletBreakpoint - 1) {
      position: fixed;
      right: 0;
      background-color: $darkpurple;
      padding: 10px 15px 10px 0;
      z-index: $zIndexChannelOptions;
      box-shadow: -15px 0 15px -4px $darkpurple;
    }
  }

  &.private {
    background-color: black;

    .messageHeaderText {
      color: $white;
    }

    .messageHeader {
      @media (max-width: $tabletBreakpoint - 1) {
        background-color: transparent;
        box-shadow: none;
      }
    }
  }

  .messageHeaderCall {
    width: 32px;
    height: 32px;
    position: relative;
    margin-left: 16px;
    margin-right: 0px;

    i {
      font-weight: 900;
      opacity: 1 !important;
      color: $white;
      position: relative;
      bottom: 4px;
      right: 4px;
    }

    @media (max-width: $computerBreakpoint - 1) {
      width: 24px;
      height: 24px;
      background-color: transparent !important;

      i {
        right: 7px;
        font-size: 16px;
        bottom: 8px;
        color: $mobileViewHeaderIconColor;

        &:hover {
          color: $white;
        }
      }
    }
  }

  .messageHeaderSubheader {
    padding: 0 10px;
    line-height: 1;

    .roomName {
      color: $primaryColor;
      font-size: 13px;
      font-weight: $fontWeightHeavy;
      letter-spacing: 1.4px;
      text-transform: uppercase;
    }

    .pinned,
    .search {
      border-left: 1px solid $lightgrey;
      cursor: pointer;
      margin-left: 5px;
      padding-left: 5px;

      &:hover {
        color: $primaryColor;
      }

      i:global(.icon) {
        font-weight: $fontWeightBold;
      }
    }

    .gitlabUrl {
      border-left: 1px solid $lightgrey;
      cursor: pointer;
      margin-left: 5px;
      padding-left: 5px;
    }

    span.pinned:first-child {
      border-left: 0;
      margin-left: 0;
      padding-left: 0;
    }
  }

  @media (max-width: $computerBreakpoint - 1) {
    background-color: $darkpurple;
    padding: 8px 15px;
  }
}

.messageHeaderSearchToggle {
  width: 24px;
  height: 24px;
  background-color: transparent !important;
  position: relative;
  margin-left: 16px !important;

  i {
    font-size: 16px;
    color: $mobileViewHeaderIconColor;
    font-weight: 900;
    bottom: 8px;
    right: 4px;
    position: relative;

    &:hover {
      color: $white;
    }
  }
}

.messageHeaderSearch {
  position: relative;
  width: 200px;
  font-size: 14px !important;

  @media (min-width: $tabletBreakpoint) and (max-width: $computerBreakpoint - 1) {
    width: 150px;
  }

  @media (max-width: $tabletBreakpoint - 1) {
    display: none;
  }

  input[type="search"] {
    background-color: $white !important;
    box-sizing: border-box;
    color: $fontColor !important;
    cursor: pointer;
    font-family: "Source Sans Pro", Arial, Helvetica, sans-serif !important;
    font-size: 14px !important;
    opacity: 0.8;
    outline: none !important;
    padding: 10px 10px 10px 37px !important;
    transition: all ease-in 0.1s !important;
    width: 100%;
    border: 1px solid #d3d3d3 !important;
    border-radius: 4px !important;

    @media (min-width: $tabletBreakpoint) and (max-width: $computerBreakpoint - 1) {
      padding: 3px 4px 3px 37px !important;
      font-size: 13px !important;
      opacity: 0.9;
    }

    @include placeholder {
      color: $fontColor;
    }

    &:active,
    &:focus {
      cursor: text;
      opacity: 1;
      transition: all ease-in 0.1s;
      border: 1px solid $black !important;
    }

    + :global(.icon) {
      font-weight: 900 !important;

      @media (min-width: $tabletBreakpoint) and (max-width: $computerBreakpoint - 1) {
        font-size: 13px !important;
      }
    }
  }

  :global(.ui.dropdown[role="listbox"]) {
    margin: 0;

    > :global(.text) {
      display: none;
    }
  }

  button[type="submit"] {
    color: #ffffff;
    font-size: 14px;
  }
}

.messageHeaderMenuWrapper {
  display: flex;
  align-items: center;
  padding: 0px 15px;
}

.messageHeaderMenu {
  border-bottom: none !important;
  margin-bottom: 0px !important;
  margin-right: auto !important;
  max-width: 100%;
  overflow-x: auto;

  &::-webkit-scrollbar {
    height: 0px;
  }

  :global(.item) {
    padding: 12px 2px !important;
    margin: 0px 16px !important;
    font-size: 14px;
    color: #4d4d4d !important;
    opacity: 0.8;

    &:first-of-type {
      margin: 0px 18px 0px 0px !important;
    }

    &:hover {
      opacity: 1;
    }

    &:focus {
      outline: none;
    }

    &.teamLink {
      i {
        margin-left: 4px !important;
        font-weight: 900;
        font-size: 10px;
        opacity: 0.5 !important;
      }
    }
  }

  :global(.item.active) {
    font-weight: $fontWeightNormal !important;
    color: $black !important;
    opacity: 1;
  }
}

.channelOptions {
  display: flex;
  align-items: center;

  @media (max-width: $tabletBreakpoint - 1) {
    display: none;
  }

  &.hasProjectUrls {
    @media (max-width: $tabletBreakpoint - 1) {
      display: flex;
    }

    @media (max-width: 919px) {
      display: none;
    }

    @media (min-width: 992px) and (max-width: 1135px) {
      display: none;
    }
  }

  :global(.button) {
    padding: 0px !important;
    background-color: transparent !important;
    margin-left: 14px !important;
    font-size: 14px !important;
    opacity: 1 !important;
    color: #4d4d4d;

    i {
      font-size: 12px !important;
      font-weight: 900;
      color: #4d4d4d;
      -webkit-transform: none !important;
      transform: none !important;
      opacity: 1 !important;
    }

    &:hover,
    &:hover i {
      color: $purple;
    }

    &.activeBtn {
      i {
        color: $primaryColor;
      }
    }
  }
}

.modalUserListItem:hover {
  background-color: $hoverColor;

  .modalUserListItemAction {
    display: block;
  }
}

.modalUserListItemAction {
  display: none;
  font-size: 12px;
  font-weight: $fontWeightBold;
  white-space: nowrap;
}

.messageListActions {
  border: 1px solid #525c6d;
  padding: 10px 30px;
}

.messageListLoader {
  margin-bottom: 10px !important;
}

.pollOptionPointAllocation {
  align-items: flex-start;
  display: flex;
  margin-bottom: 12px;
}

.pollOption {
  word-break: break-word;
}

:global(.ui.input).pollOption {
  font-size: 15px;

  input {
    border-radius: 2px !important;
    margin-right: 10px;
    padding: 2px 0 !important;
    text-align: right;
    width: 60px;
  }
}

:global(.ui.checkbox).pollOption {
  display: block !important;
  margin-bottom: $pollOptionMarginBottom;

  &.last {
    margin-bottom: $pollOptionMarginBottom - 10;
  }

  img {
    display: inline-block;
    max-height: 350px;
    max-width: 100%;
  }

  input {
    height: $pollOptionCheckboxSize + 2;
    width: $pollOptionCheckboxSize + 2;
  }

  label {
    font-size: 14px;
    color: #212121;
    line-height: $pollOptionCheckboxSize / 15 * 17 !important;
    overflow: auto !important;
    padding-left: $pollOptionCheckboxSize + $pollOptionCheckboxMargin !important;
  }

  label:before {
    background-color: $pollOptionOffBgColor !important;
    border: none;
    border-radius: 4px !important;
    height: $pollOptionCheckboxSize !important;
    top: 2px;
    transition: background-color $pollOptionTransition;
    width: $pollOptionCheckboxSize !important;
  }

  label:after {
    border-radius: 4px !important;
    height: $pollOptionCheckboxSize !important;
    top: 2px;
    transition: background-color $pollOptionTransition;
    width: $pollOptionCheckboxSize !important;
    z-index: 1;
  }

  &:global(.radio) {
    label:before {
      border-radius: $pollOptionCheckboxSize / 2 !important;
    }

    label:after {
      border-radius: $pollOptionCheckboxSize / 2 !important;
    }
  }
}

:global(.ui.checkbox.radio).pollOption {
  label:after {
    background-color: $pollOptionDotColor !important;
  }
}

:global(.ui.checkbox.radio.checked).pollOption {
  label:before {
    background-color: $pollOptionOnBgColor !important;
  }
}

.pollOptionEdit {
  align-items: center;
  display: flex;
  margin-bottom: 8px;
}

.pollOptionEditName {
  flex: 1 0;
  line-height: 1 !important;
  margin: 0 10px 0 5px !important;

  :global(.markdown-textarea) {
    margin-bottom: 0;
  }

  :global(.ui.input) {
    width: 100%;
  }

  input[type="text"],
  textarea {
    font-size: 15px !important;
    line-height: 1.8 !important;
    padding: 5px 30px 5px 10px !important;
  }
}

.pollOptionEditSortHandle {
  align-items: center;
  color: $grey;
  display: flex !important;
  margin: 0 !important;
}

.pollTag {
  border: none;
  background-color: $offwhite;
  border-radius: 14.5px;
  color: $darkgrey;
  display: inline-block;
  font-family: inherit;
  font-size: 12px;
  line-height: 1;
  margin-right: 3px;
  padding: 3px 10px;

  &.editable {
    cursor: pointer;

    i {
      margin: 0 0 0 5px;
      opacity: 0.6;
    }
  }
}

.pollAnonymousTag {
  color: rgba(0, 0, 0, 0.5);
  margin-right: 8px;
}

.projectLinkButton {
  display: flex;
  width: auto;
  padding: 10px 20px;
}

.projectLinkButton.disabled,
.projectLink.disabled {
  opacity: 0.45;
  pointer-events: none;
  cursor: default;
}

.screenHeader {
  border-bottom: solid 1px $lightgrey;
  position: relative;
}

.screenHeaderClose {
  @include buttonLink($grey, $darkgrey);
  line-height: 48px;
  position: absolute;
  right: 20px;
  top: 0;
}

.screenHeaderTitle {
  color: $primaryColor;
  font-size: 16px;
  font-weight: $fontWeightBold;
  line-height: 50px;
  margin: 0;
  text-align: center;
}

.sidebarPusher {
  height: 100%;
}

.projectLink,
:global(.ui.button).projectLink {
  background: transparent;
  border-radius: 4px;
  border: 1px solid #e0e0e0;
  display: block;
  font-size: 16px;
  height: 45px;
  margin-bottom: 12px;
  overflow: hidden;
  padding: 10px 7px;
  text-align: center;
  text-overflow: ellipsis;
  width: 100%;
  white-space: nowrap;

  &:link,
  &:visited {
    color: #757575;
  }

  &:active,
  &:hover {
    color: $black;
    border-color: $black;
  }
}

.textareaHelp {
  padding: 3px 15px;
  margin-top: -12px;
}

.checkboxContainer {
  padding: 1rem;
  font-size: 0.875rem;

  &.roomSettings {
    :global(.ui.checkbox) {
      margin: 0 0 1rem;
    }
  }
}

.settingsHeader {
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.6875rem;
  text-transform: uppercase;
  padding: 0.5rem 1rem 0 1rem;
}

.settingsLink {
  color: $linkColor;
  cursor: pointer;
  float: right;
  text-transform: none;

  &:hover {
    color: $linkColor;
  }
}

.deleteLabel {
  color: $deleteLabelColor;
}

.sidePanelEdit {
  font-size: 14px !important;
  font-weight: 900 !important;
  left: 20px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.sidePanelHeader {
  border-bottom: 1px solid $lightgrey !important;
  font-size: 16px !important;
  margin: 0 !important;
  position: relative;
  text-align: center;
}

.sidePanelHeaderText {
  color: $primaryColor;
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 48px !important;
  margin: 0 !important;
}

.sidePanelSearch {
  position: relative;
  width: 100%;
  font-size: 14px !important;

  input[type="search"] {
    background-color: $white !important;
    box-sizing: border-box;
    color: $fontColor !important;
    cursor: pointer;
    font-family: "Source Sans Pro", Arial, Helvetica, sans-serif !important;
    font-size: 14px !important;
    opacity: 0.8;
    outline: none !important;
    padding: 10px 10px 10px 37px !important;
    transition: all ease-in 0.1s !important;
    width: 100%;
    border: 1px solid #d3d3d3 !important;
    border-radius: 4px !important;

    @include placeholder {
      color: $fontColor;
      opacity: 0.4;
    }

    &:active,
    &:focus {
      cursor: text;
      opacity: 1;
      transition: all ease-in 0.1s;
      border: 1px solid $black !important;
    }

    + :global(.icon) {
      font-weight: 900 !important;
    }
  }

  :global(.ui.dropdown[role="listbox"]) {
    margin: 0;

    > :global(.text) {
      display: none;
    }
  }

  button[type="submit"] {
    color: #ffffff;
    font-size: 14px;
  }
}

.sidePanelBoxShadow {
  box-shadow: 0 2px 4px 0 #e0e0e0;
}

@media (max-width: $tabletBreakpoint) {
  .modalRename:global(.ui.modal) {
    margin-left: 0 !important;
    margin-right: 0 !important;

    .modalRenameHeader:global(.header) {
      padding: 0 !important;

      i,
      span {
        font-size: 18px !important;
      }

      :global(.content) {
        padding: 0;

        span {
          display: block;
          white-space: nowrap;
          max-width: 210px;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    :global(.content),
    :global(.actions) {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    :global(.ui.input) {
      font-size: 14px;
    }

    :global(.actions .button) {
      margin-left: 0;
      font-size: 13px;
    }
  }
}
