@import "../../theme/mixins";
@import "../../theme/variables";

.notification {
  align-items: center;
  align-self: center;
  border-radius: 4px;
  background-color: $darkgrey;
  color: $white;
  display: flex;
  font-size: 14px;
  justify-content: space-between;
  padding: 12px 16px;
  position: absolute;
  z-index: $zIndexNotification;
  box-shadow: 0px 2px 4px #a6a6a6;
  margin-top: 9px;
}

.notificationButton {
  background: none !important;
  color: $white !important;
  margin-left: 10px !important;
  padding: 0 !important;

  &:hover {
    opacity: 0.75;
  }

  &:active {
    opacity: 0.5;
  }
}
