@import "../../theme/variables";
@import "../../theme/mixins";

:global(.ui.basic.modal).modal {
  width: unset;

  > :global(.close) {
    top: 0;
  }

  .previous {
    position: absolute;
    border-radius: 50%;
    left: -45px;
    top: 50%;
    transform: translateY(-50%);
  }

  .next {
    position: absolute;
    border-radius: 50%;
    right: -50px;
    top: 50%;
    transform: translateY(-50%);
  }

  :global(.description) {
    text-align: center;

    img {
      max-height: calc(100vh - 200px);
      max-width: calc(100vw - 100px);
    }
  }

  :global(.actions) {
    background-color: $black;
    padding: 0.5rem;
    white-space: nowrap;

    .caption {
      float: left;
      padding: 5px;
    }
  }
}

.imageTrigger {
  min-height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e8e8e8;
  background-size: contain;

  img {
    max-height: 264px !important;
    max-width: 316px !important;

    @media (max-width: $tabletBreakpoint) {
      max-width: 226px !important;
      max-height: 175px !important;
    }
  }

  i {
    font-size: 15px !important;
    color: #424242 !important;
    font-weight: normal;
  }

  &.borders {
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    border-top: 1px solid #e8e8e8;
    border-left: 1px solid #e8e8e8;
    border-right: 1px solid #e8e8e8;

    img {
      border-top-left-radius: 2px;
      border-top-right-radius: 2px;
    }
  }

  .hidden {
    display: none;
  }
}

:global(.sidePanel) {
  .imageTrigger {
    img {
      max-width: 286px !important;
      max-height: 235px !important;

      @media (max-width: $tabletBreakpoint) {
        max-width: 226px !important;
        max-height: 175px !important;
      }
    }
  }
}
