@import "../../theme/mixins";
@import "../../theme/variables";

$bold: 600;

$bellColor: $white;

$baseFontColor: $fontColor;
$checkboxColor: $fontColor;

.container {
  flex: 1;
  overflow: auto;
  background-color: $white;
}

.notifContainer {
  margin-top: 1.5rem !important;
  max-width: 600px;
  margin: auto;

  .filterTrigger {
    @include buttonLink($darkgrey, $text-decoration: none);
    font-size: 0.875rem !important;
    font-weight: $bold !important;
  }
}

.heroBanner {
  background: $white;
  border-bottom: 1px solid $lightgrey;
  padding: 1.375rem 0;
  text-align: center;

  h1 {
    font-size: 1.5rem !important;
    line-height: 1.625rem !important;
    font-weight: normal !important;
    margin-bottom: 0;
  }
}

.feedDate {
  float: right !important;
}

.markRead {
  margin-bottom: 1.25rem;
  float: right;
}

.markReadButton {
  @include buttonLink($darkgrey, $text-decoration: none);
  font-size: 0.875rem !important;
  font-weight: $bold !important;
}

:global(.ui.feed) > .notifEvent:global(.event) {
  background-color: $white;
  border-bottom: 1px solid $lightgrey;
  padding: 0.7rem 0.9375rem;

  &.unreadEvent {
    background: #eef9fe;
  }

  & > .notifContent:global(.content) {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0.85rem;
    word-break: break-word;
    white-space: pre-wrap;

    :global(.summary) {
      font-weight: normal;

      a {
        font-weight: 600;
        color: $baseFontColor;
      }
    }

    .eventLink:global(.extra.text) {
      font-size: 0.75rem;
      cursor: pointer;
      color: $primaryColor;
      font-weight: $bold;
    }

    .emptyLink:global(.extra) {
      height: 1rem;
    }
  }
}

.filterContainer {
  box-shadow: none !important;
  border-radius: 4px !important;
  margin-top: 2.8185rem !important;
  border: 1px solid $lightgrey !important;
  padding: 1.25rem 1.25rem !important;
}

.filterHeader {
  font-size: 0.875rem;
  text-transform: uppercase;
  color: $darkgrey;
  font-weight: $bold;
}

.filterOption {
  color: $checkboxColor;
  font-size: 1rem !important;
  display: block !important;
  margin-bottom: 1.0625rem !important;
}

.pageButton {
  margin: 0.625rem !important;
  background-color: $white !important;

  &:hover {
    background-color: $white !important;
  }
}

.pagination {
  text-align: center;
}

.feedEmoji {
  margin-right: 0.9375rem;
  width: 1.375rem;

  :global(.emoji-mart-emoji) > span {
    height: 1.25rem !important;
    width: 1.25rem !important;
    margin-top: 0.5rem !important;
  }
}

.noNotifs {
  background-color: $white;
  border: 1px solid $lightgrey;
  border-radius: 4px;
  color: $checkboxColor;
  clear: both;
  padding: 0.5rem;
}

.pollAvatar {
  :global(i.circular.icon) {
    height: 35px !important;
    width: 35px !important;
  }
}

.notifBell:global(.ui.basic.button) {
  box-shadow: none !important;
  margin: 0 10px 0 0 !important;
  padding: 0.5em 0.5em 0.5em !important;
  position: relative;

  &:active,
  &:focus,
  &:hover {
    background-color: rgba(0, 0, 0, 0.12) !important;
  }

  &.active {
    background-color: rgba(0, 0, 0, 0.2) !important;
  }

  i {
    color: $bellColor;
    font-weight: 900;
    opacity: 1 !important;
  }
}

.notifBellUnread {
  background-color: $orange;
  border-radius: 11px;
  border: solid 1.5px $darkpurple;
  height: 11px;
  position: absolute;
  right: 4px;
  top: 4px;
  width: 11px;
}

@media screen and (max-width: 630px) {
  .notifContainer {
    margin: 1.5rem !important;
  }
}

:global(.ui.button) {
  &.linkButton {
    color: $baseFontColor;
    font-weight: 600;
    display: inline;
    background: none;
    padding: 0;
    margin: 0;
    white-space: normal;
  }
}
