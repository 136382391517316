@import "../../theme/variables.scss";
@import "../../theme/mixins.scss";

.newVersion {
  background-color: $darkgrey;
  color: $white;
  left: 50%;
  max-width: 90vw;
  padding: 10px;
  position: absolute;
  text-align: center;
  top: 20px;
  transform: translateX(-50%);
  width: 500px;

  .reload {
    color: gold;
    cursor: pointer;
    display: inline-block;
    font-weight: $fontWeightBold;
    padding: 0 15px;
    text-transform: uppercase;
  }
}
