@import "../../theme/variables";
@import "../../theme/mixins";

$hoverColor: rgba($blue, 0.1);

.sectionMemberMatch {
  cursor: pointer;
  margin: 2px 0;
  overflow: hidden;
  padding: 0 10px;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:first-of-type {
    margin-top: 0;
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  &:hover,
  &.selected {
    background: rgba($blue, 0.1);
  }
}

.sectionMemberMatchAvatar {
  display: inline-block;
  margin-right: 5px;
  vertical-align: top !important;

  i {
    line-height: 16px !important;
  }
}

.options {
  list-style: none;
  margin: 0;
  margin-top: 15px;
  padding: 0;
}

.manageMemberListItem {
  align-items: center;
  border-bottom: 1px solid $borderColor;
  display: flex;
  padding: 10px;

  &:hover {
    background-color: $hoverColor;
    font-weight: $fontWeightBold;
  }

  &:last-of-type {
    border-bottom: none;
  }

  &.clickable {
    cursor: pointer;
  }

  &.selected {
    background-color: $hoverColor;
  }
}

.manageMemberListItemName {
  margin-left: 20px;
  flex-grow: 1;
  flex-shrink: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media (max-width: $tabletBreakpoint) {
  .mainModal:global(.ui.modal) {
    margin-left: 0 !important;
    margin-right: 0 !important;

    :global(.close.icon) {
      top: -0.5rem !important;
    }

    :global(.header) {
      padding: 0 !important;
      font-size: 18px !important;
    }

    :global(.content) {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    :global(.actions) {
      padding-left: 0 !important;
      padding-right: 0 !important;

      :global(.button) {
        margin-left: 0;
        font-size: 13px;
      }
    }
  }
}
