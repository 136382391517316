.datetimePicker {
  display: inline-block;
  padding-right: 5px;
  vertical-align: middle;
  width: 50%;

  &:last-child {
    padding-left: 5px;
    padding-right: 0;
  }

  &.small {
    width: auto;
  }
}

.datetimePickerLabel {
  margin: 0;
}

.datetimePickerBlock {
  display: block;
  margin-bottom: 10px;
}
