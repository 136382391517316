@import "../../theme/variables";
@import "../../theme/mixins";

:global(.ui.segment).ringer {
  border-radius: 10px;
  cursor: move;
  left: 30px;
  max-width: 100%;
  position: absolute;
  top: 30px;
  width: 300px;
  z-index: 10000;

  @media (min-width: 481px) {
    left: 300px;
    top: 100px;
  }

  i:global(.icon.close) {
    cursor: pointer;
    padding: 20px;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 10001;

    &:hover,
    &:active,
    &:focus {
      color: $white;
    }
  }

  h1 {
    font-size: 15px;
    margin: 15px 0 0 0;
    padding-top: 0;
  }

  h2 {
    font-size: 15px;
    font-weight: 200;
    margin: 0 0 15px 0;
    padding-bottom: 0;
  }
}

.callModalAvatar {
  animation-duration: 2s;
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 4;
  animation-name: avatar-bounce;
}

@keyframes avatar-bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-3px);
  }
}
