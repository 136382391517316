@import "../../theme/variables";

.formattingTools {
  display: flex;

  .trigger.activeTool:global(.ui.basic.button) {
    background-color: #e0e0e0 !important;
  }

  .toolsContainer {
    display: none;

    &.active {
      display: flex;
      background-color: #e0e0e0;
    }

    :global(.ui.basic.button) {
      > :global(.icon) {
        font-size: 11px !important;
      }

      &:hover,
      &:active {
        background-color: transparent !important;
      }
    }
  }
}

@media (min-width: $computerBreakpoint) {
  .formattingTools {
    .trigger.activeTool:global(.ui.basic.button) {
      margin-right: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    .toolsContainer.active {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      border-left: 1px solid #d3d3d3;
    }
  }
}

:global(.sidePanel) {
  .formattingTools.activeContainer {
    display: block;

    .trigger.activeTool:global(.ui.basic.button) {
      margin-right: 0.25em;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    .toolsContainer.active {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      align-items: center;
      justify-content: center;
      background-color: #e0e0e0;
      border-color: transparent;
      border-top-right-radius: 0;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      padding: 3px 0;
      flex-wrap: wrap;
    }
  }
}

@media (max-width: $computerBreakpoint) {
  .formattingTools.activeContainer {
    display: block;

    .toolsContainer.active {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      align-items: center;
      justify-content: center;
      background-color: #e0e0e0;
      padding: 3px 0;
      flex-wrap: wrap;
      border-color: transparent;

      &.roundedBottomCorners {
        border-top-right-radius: 0;
        border-top-left-radius: 0;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }
  }

  :global(.ui.modal.form) {
    .toolsContainer.active {
      :global(.ui.basic.button) {
        padding: 3px 4px !important;

        > :global(.icon) {
          font-size: 10px !important;
        }

        &:hover,
        &:active {
          background-color: transparent !important;
        }
      }
    }
  }
}
