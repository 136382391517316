@import "../../../theme/mixins";
@import "../../../theme/variables";

$palette: (#eb5757, #f2994a, #f2c94c, #219653, #56ccf2, #2d9cdb, #685ace, #4f4f4f);

.container {
  background-color: $white;
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1 1;
  overflow: hidden;
  position: relative;

  @media (max-width: 990px) {
    // side panel is visible so hide the container
    &.sidePanelVisible {
      display: none;
    }
  }

  .back {
    align-items: center;
    border-bottom: 1px solid $lightgrey;
    border-top: 1px solid $lightgrey;
    display: flex;
    height: 50px;
    padding: 15px 25px;

    &:link,
    &:visited {
      color: $black;
      font-size: 12px;
      font-weight: $fontWeightBold;
      text-transform: uppercase;
    }

    &:active,
    &:hover {
      text-decoration: underline;
    }
  }
}

.navigation {
  background: transparent !important;
  border-bottom: 1px solid #e0e0e0 !important;
  margin: 0 !important;
  overflow-y: hidden;
  width: 100%;

  :global(.item) {
    padding: 0 1.25rem !important;
    border-bottom: 2px solid transparent !important;

    > a,
    > span {
      color: $grey !important;
      cursor: pointer;
      font-size: 0.9375rem !important;
      font-weight: 600 !important;
      line-height: 1.5rem !important;
      padding-bottom: 13px !important;
      padding-top: 13px !important;
      outline: none;
    }

    &:global(.active) > a,
    &:global(.active) > span {
      border-bottom: 2px solid $black !important;
      color: $black !important;
      padding-bottom: 11px !important;
      outline: none;

      @media screen and (max-width: 400px) {
        padding-bottom: 4px;
      }
    }
  }
}

.main {
  overflow: auto;
  padding: 20px;
  display: flex;
  flex: 1;
  flex-direction: column;
}

.timezoneViewerContainer {
  display: flex;
  padding-left: 15px;
  padding-right: 15px;
  flex: 1;
  position: relative;
  overflow: auto;
}

.meetingContainer {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;

  @media (max-width: 991px) {
    display: block;
  }
}

.meetingSidebar {
  border-right: 1px solid $borderColor;
  margin-right: 10px;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  width: 250px;

  .meetingCalendarWidget {
    margin-left: -20px;
    overflow: hidden;
  }

  .calendarViewContainer {
    margin-right: 1.25rem;

    .calendarViewHeader {
      font-weight: 600;
      line-height: 24px;
      margin-bottom: 0.5rem;
    }

    .membersList {
      width: 200px;
      margin-left: 24px;
      position: relative;

      .checkBoxContainer {
        display: flex;
        flex-direction: row;

        .memberCheckbox {
          line-height: 24px;
        }
        .memberSearchDisplay {
          position: relative;
          padding-left: 24px;
          display: flex;
          flex-direction: row;

          .contactModal {
            padding: 0;
          }

          .userAvatar {
            left: -1px;
            top: -4px;
            position: absolute;
          }
        }
      }

      .membersSearch {
        width: 175px;
        font-size: 16px;
        line-height: 24px;
        padding-left: 24px;
        border: none;
        border-bottom: 1px solid #c4c4c4;
        background-image: url("../assets/search.svg");
        background-repeat: no-repeat;
        background-position: 6px 7px;
        color: #212121;
        margin-bottom: 15px;

        ::placeholder {
          color: #bdbdbd;
        }
        &:focus {
          outline: none;
        }
      }
    }
  }
}

.meetingCalendar {
  flex: 1;

  .calendarContainer {
    :global(.rbc-events-container) {
      margin-right: 0;
    }

    .calendarEvent {
      background-color: $lightgrey;
      border-color: $white;
      border-radius: 2px;
      color: $fontColor;
      font-size: 12px;
      padding: 0;

      @for $i from 1 through 8 {
        &.calendarEvent--#{$i} {
          border-color: nth($palette, $i);
          background-color: rgba(nth($palette, $i), 0.2);

          &:hover {
            background-color: rgba(nth($palette, $i), 0.3);
            filter: brightness(0.7);
          }
        }
      }

      :global(.rbc-event-label) {
        display: none;
      }

      :global(.rbc-event-content) {
        padding: 0 5px;

        .eventTitle {
          margin-bottom: -2px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .eventTime {
          font-size: 90%;
        }
      }
    }
  }
}

.timezoneViewerGroup {
  flex: 0 0;

  &.sticky {
    left: 0;
    position: sticky;
    z-index: 5;

    > .timezoneViewerGroupDetail {
      background-color: $white;
    }
  }
}

.timezoneViewerGroupDetail {
  align-items: center;
  border-bottom: 1px solid $borderColor;
  display: flex;
  height: 70px;
  padding: 10px 0 10px 15px;
  width: 350px;

  @media (max-width: 767px) {
    width: 70px;
  }

  .deleteBtn {
    &:global(.ui.basic.button) {
      box-shadow: none;
      margin-left: -15px;
      padding: 0.25em;
      width: 15px;

      &:hover {
        color: $red !important;
      }
    }
  }
}

.timezoneViewerGroupDetailDescription {
  margin-left: 8px;
  overflow: hidden;

  &.right {
    flex: 1 0 auto;
    margin-right: 15px;
    text-align: right;

    @media (max-width: 767px) {
      text-align: center;
    }
  }
}

.timezoneViewerGroupDetailName {
  font-weight: $fontWeightBold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  a:link,
  a:visited {
    color: $fontColor;
  }

  a:hover,
  a:active {
    color: black;
  }
}

.timezoneViewerGroupDetailTimezone {
  color: $grey;
  font-size: 14px;
}

.timezoneViewerHeader {
  align-items: center;
  display: flex;
  flex: 0;
  height: 50px;

  :global(.ui.basic.button) {
    color: $fontColor !important;
    padding: 0.75em 0.5em;

    &.noBorder {
      box-shadow: none;
    }
  }

  .timezoneViewerGroup {
    flex: 0 0 315px;
    margin-left: 15px;
    margin-right: 20px;

    @media (max-width: 767px) {
      flex: 0 0 230px;
    }
  }

  .timezoneViewerTimeline {
    display: flex;

    .dayHeaderContainer {
      display: flex;
      height: 50px;
      min-width: 80px;
      overflow: hidden;

      .dayHeaderDisplay {
        border-radius: 5px;
        color: $grey;
        cursor: pointer;
        display: inline-block;
        flex: 1 0 80px;
        font-size: 12px;
        line-height: 14px;
        padding: 11px 0;
        text-align: center;
        width: 80px;

        &.active {
          background-color: $primaryColor;
          color: $white;
        }
      }
    }
  }
}

.calendarWidgetModal {
  :global(.DayPicker) {
    margin: auto;
  }
}

.timezoneViewerTimeline {
  flex-grow: 1;
  min-width: 0;
}

.timelineContainer {
  position: relative;
}

.timeline {
  align-items: center;
  display: flex;

  .timelineHourItem {
    border-bottom: 1px solid $borderColor;
    flex: 0 0 50px;
    height: 70px;
    padding: 10px 0;
    text-align: center;

    &.newDay {
      &:not(:first-child) {
        padding-left: 2px;
      }
    }

    &.endDay {
      padding-right: 2px;

      .timelineHourDisplay {
        border-bottom-right-radius: 5px;
        border-top-right-radius: 5px;
        border-right-color: $borderColor;
      }
    }

    .timelineHourDisplay {
      background-color: $white;
      border: 1px solid $borderColor;
      border-left-width: 0;
      border-left-color: transparent;
      border-right-width: 0;
      border-right-color: transparent;
      color: $primaryColor;
      height: 50px;

      &.inRange {
        -moz-user-select: none;
        -webkit-user-select: none;
      }
    }

    .timelineHour {
      font-size: 14px;
      font-weight: $fontWeightMedium;
      padding-top: 5px;
    }

    .timelineMeridiem {
      color: $grey;
      display: block;
      font-size: 12px;
      margin-top: -10px;
    }

    .timelineDate {
      background-color: $white;
      border: 1px solid $borderColor;
      border-bottom-left-radius: 5px;
      border-top-left-radius: 5px;
      border-right-width: 0;
      border-right-color: transparent;
      color: $primaryColor;
      font-size: 14px;
      font-weight: $fontWeightHeavy;
      line-height: 48px;

      &.inRange {
        -moz-user-select: none;
        -webkit-user-select: none;
      }
    }
  }
}

.timelineOverlay {
  align-items: center;
  display: flex;
  position: absolute;
  top: 0;

  .hourOverlay {
    background-color: rgba($white, 0.5);
    z-index: 1;
    -moz-user-select: none;
    -webkit-user-select: none;

    &.inRange {
      background-color: transparent;
    }

    &:not(.rangeSelected):hover {
      border: 2px solid $primaryColor;
      border-radius: 5px;
    }
  }
}

.timelineOverlayControl {
  border-bottom: 2px solid $primaryColor;
  border-radius: 5px;
  border-top: 2px solid $primaryColor;
  position: absolute;
  top: 0;
  -moz-user-select: none;
  -webkit-user-select: none;

  .timelineOverlayControlLeft {
    border-left: 2px solid $primaryColor;
    cursor: w-resize;
    left: 0;
    position: absolute;
    width: 5px;
    z-index: 2;
    -moz-user-select: none;
    -webkit-user-select: none;
  }

  .timelineOverlayControlRight {
    border-right: 2px solid $primaryColor;
    cursor: e-resize;
    position: absolute;
    right: 0;
    width: 5px;
    z-index: 2;
    -moz-user-select: none;
    -webkit-user-select: none;
  }
}

.timezoneViewerLegend {
  display: flex;
  flex: 0;
  justify-content: center;
  margin-bottom: 15px;
  margin-top: 15px;

  @media (max-width: 767px) {
    margin-left: 70px;
  }

  > div {
    display: flex;
    align-items: center;
  }

  i {
    width: 15px;
    height: 15px;
    border-radius: 11px;
    display: block;
    margin-right: 5px;
    margin-left: 15px;

    &.available {
      border: 1px solid #bdbdbd;
    }
    &.busy {
      background-color: #685ace;
    }
    &.outsideWorkingHours {
      background-color: #c4c4c4;
    }
    &.unavailableForWork {
      background-color: #eb5757;
    }
  }

  span {
    color: $darkgrey;
    font-size: 12px;
  }
}

.headerDate {
  font-size: 16px;
  font-weight: bold;
}

:global(.rbc-overlay) {
  @for $i from 1 through 8 {
    .calendarEvent--#{$i} {
      color: black;
      font-size: 12px;
      border-color: nth($palette, $i) !important;
      background-color: rgba(nth($palette, $i), 0.2) !important;

      &:hover {
        background-color: rgba(nth($palette, $i), 0.3) !important;
      }
    }
  }
}
