.list {
  display: flex;
  max-height: 508px;
}

.listContainer{
  flex: 1;
  max-height: 508px;
  overflow-y: auto; 
  overflow-x: hidden; 
  padding-right: 5px;

  @media (max-width: 991px) {
    padding-left: 0;
  }
}

.peopleList {
  flex: 1;
  border: 1px solid #E0E0E0;
  box-sizing: border-box;
  border-radius: 2px;
  padding: 30px 20px 10px 20px;
  overflow: visible;
}

.peopleContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 20px;
}

.user {
  display: flex;
}

.action {
  display: flex;
  padding-top: 6px;
}

.name {
  font-size: 14px;
  padding-top: 6px;
  margin-left: 11px;
  word-wrap: break-word;

  &.isbldaiParentNode {
    padding-top: 0 !important;
    position: relative;
    top: -4px;
  }

  @media (max-width: 991px) {
    max-width: 90%;
  }

  a {
    text-decoration: none;
    color: #212121;
  }

  .isbldai {
    font-size: 13px;
    color: #828282;
  }
}

.bullet{
  color: #C4C4C4;
  margin: 11px,
}

.role {
  font-size: 12px;
  color: #828282;
  padding: 4px 12px;
  border: 1px solid #BDBDBD;
  box-sizing: border-box;
  border-radius: 20px;
}

.added {
  flex-direction: row;
  color: #BDBDBD;
  font-size: 16px;
  min-width: 110px;
  margin-left: 5px;
}

.add {
  flex-direction: row;
  color: #685ACE;
  cursor: pointer;
  font-size: 16px;
  margin-left: 5px;
  min-width: 110px;

}
