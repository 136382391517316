@import "../../../../theme/variables";

.members {
  @media (max-width: 359px) {
    p {
      max-width: 100px;
    }
  }

  .content {
    padding: 0px 16px 16px 16px;

    .search {
      padding: 16px 0;

      input {
        font-size: 14px;
        line-height: 21px;
        color: #4d4d4d;
        border: 1px solid #d3d3d3;

        &:active,
        &:focus {
          border: 1px solid #212121;
        }
      }

      i {
        color: #212121;
        opacity: 1 !important;
        font-weight: 900;
      }
    }

    .memberItem {
      display: flex;
      padding: 8px 0;

      .detail {
        margin-left: 16px;
        display: flex;
        align-items: center;
        font-size: 14px;
        line-height: 18px;
        color: #212121;
      }

      .detail a {
        color: #212121;
      }
    }

    .label {
      text-transform: uppercase;
      font-size: 13px;
      line-height: 16px;
      letter-spacing: 0.05px;
      color: #a6a6a6;

      &.memberLabel {
        padding-top: 16px;
      }
    }
  }
}
