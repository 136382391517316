@import "../../../../theme/variables";

.attachments {
  min-height: 100%;

  .heading {
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
      color: #212121;
      font-size: 24px;
      line-height: 29px;
      margin: 0;
    }

    .attachmentButton {
      font-size: 14px;
      line-height: 21px;
      font-weight: normal;
      letter-spacing: -0.0373333px;
      border: 1px solid #d3d3d3;
      color: #4d4d4d !important;
      box-shadow: none !important;
      background-color: $white;

      &.close {
        display: none;

        @media (max-width: $tabletBreakpoint - 1) {
          display: inline-block;
        }
      }

      @media (max-width: $tabletBreakpoint - 1) {
        font-size: 12px;
        padding: 8px 16px;
      }
    }
  }

  .content {
    padding: 16px;

    .attachmentContainer {
      display: flex;
      margin: 2px 2px 8px 2px;
      min-height: 48px;
      align-self: flex-end;
      width: 320px;
    }

    .attachment {
      padding: 8px;
      display: flex;
      flex-grow: 1;
      min-height: 48px;
    }

    .attachmentIcon {
      height: 48px;
      width: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #e8e8e8;
      border-radius: 2px;

      i {
        font-size: 15px;
        line-height: 17px;
        color: #424242;
        font-weight: normal;
      }
    }

    .attachmentDetail {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;

      p {
        margin: 0;
      }

      .heading {
        font-size: 14px;
        line-height: 18px;
        color: #212121;
        font-weight: normal;
        letter-spacing: -0.0373333px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 200px;
        display: inline;

        @media (max-width: $tabletBreakpoint) {
          max-width: 140px;
        }

        a {
          color: #212121 !important;
        }
      }

      .subheading {
        font-size: 12px;
        line-height: 15px;
        color: #a6a6a6;
        letter-spacing: 0.14px;
        font-weight: normal;
      }

      @media (max-width: $tabletBreakpoint) {
        max-width: 150px;
      }
    }
  }
}

.attachmentButton {
  display: flex;
  align-items: center;

  :global(.ui.basic.button) {
    padding: 3px 6px;
    color: #909090 !important;
    background: transparent !important;
    border: 1px solid transparent;
    border-right-width: 0;
    box-shadow: none !important;
    font-size: 12px;
    height: 25px;
    text-align: center;
    vertical-align: baseline;

    &:hover,
    &:active {
      box-shadow: none !important;
      background: transparent !important;
    }

    > :global(.icon) {
      height: 12px;
      vertical-align: baseline;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;

      padding-bottom: 13px;
      border-bottom: 1.45px solid #909090;
    }
  }
}

.attachmentImageTrigger {
  max-width: 270px;
  min-width: 48px;
  border-radius: 0 !important;
  border: none !important;
  margin-right: 12px;
  justify-content: flex-start;
  background-color: transparent !important;

  img {
    max-width: 270px !important;
    max-height: 270px !important;
  }

  @media (max-width: $tabletBreakpoint) {
    max-width: 200px;

    img {
      max-width: 200px !important;
      max-height: 200px !important;
    }
  }
}

:global(.ui.basic.modal).modal {
  > :global(.close) {
    top: -2rem;

    @media (max-width: $computerBreakpoint) {
      top: -35px !important;
      right: -10px !important;
    }
  }

  :global(.content) {
    background: $white;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  :global(.actions) {
    background-color: $black;
    padding: 0.5rem;
    white-space: nowrap;
  }
}
