@import "../../../theme/variables.scss";

.google {
  background-color: #3772d5 !important;
  color: #ffffff !important;
  display: flex !important;
  align-items: center;
  position: relative;

  img {
    position: absolute;
    top: 50%;
    left: 30px;
    transform: translateY(-50%);
  }

  .googleButtonText {
    margin: auto;
    font-size: 16px;
  }
}

.button:global(.ui.button) {
  background: unset;
  margin-left: 0 !important;
  padding: 0 !important;
  vertical-align: middle;

  &:hover {
    opacity: 0.65;
  }

  &.microsoft {
    display: block;
    color: white;
    font-size: 80%;
    background-color: $black;
    width: 24px;
    height: 24px;
    margin: -1px 5px 0 calc(3px - .1675em) !important;

    &:hover, &:focus {
      background-color: $black;
    }
  }

}
