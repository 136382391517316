@import "../../../../theme/variables";

.channelInfo {
  .channelButton {
    font-size: 14px;
    line-height: 21px;
    font-weight: normal;
    letter-spacing: -0.0373333px;
    border: 1px solid $primaryColor;

    &:hover {
      border-color: $primaryColor !important;
      color: $white !important;
    }

    @media (max-width: $tabletBreakpoint - 1) {
      font-size: 12px;
      padding: 8px 12px;
    }
  }

  .content {
    padding: 25px 16px 16px 16px;

    .inputContainer {
      margin-bottom: 16px;
    }

    .inputField {
      border-radius: 4px;
      font-size: 14px;
      line-height: 21px;
      background-color: #ffffff;
      opacity: 1;

      &:global(.ui.input > input) {
        color: #4d4d4d;
        border: 1px solid #d3d3d3;
        opacity: 0.7;

        &:active,
        &:focus {
          border: 1px solid #212121;
          color: #212121;
          opacity: 1;
        }
      }

      &:global(.ui.input.disabled > input) {
        background-color: #e8e8e8;
        border: 1px solid transparent;
        color: #4d4d4d;
      }

      &.isDirty:global(.ui.input > input) {
        color: #212121;
        border: 1px solid #212121;
        opacity: 1;
      }
    }
  }
}

.alertError {
  margin: 4px 0;
}
