@import "../../theme/variables.scss";
@import "../../theme/mixins.scss";

.contactModal {
  align-items: center;
  display: flex !important;

  :global(.text) {
    margin-right: 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  :global(.dropdown.icon) {
    display: none;
  }

  .messageListItemEventGuestItemName {
    flex: 1;
    font-size: 14px;
    margin-left: 10px;
    line-height: 1.5em;
    cursor: pointer;
    color: $black;

    &.messageListItemEventExternalGuest {
      margin-left: 4px;
    }
  }

  .contactModalMenu {
    font-size: 15px !important;
    min-width: 200px !important;
    max-width: 280px !important;
    left: 0;
    right: auto !important;

    @media (max-width: $tabletBreakpoint) {
      width: 200px;
    }

    .contactModalDivider {
      margin: 10px 0;
    }
  }

  .contactModalMenuHeader {
    align-items: center;
    display: flex;
  }

  .contactModalMenuHeaderText {
    color: $black;
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
    justify-content: space-between;
    margin: 0 8px;
  }

  .contactModalMenuHeaderTextSubtitle {
    font-size: 13px;
    line-height: 1.38;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .contactModalMenuHeaderTextTitle {
    font-size: 15px;
    font-weight: $fontWeightBold;
    line-height: 1.2;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .contactModalLinks {
    float: right;
    margin-right: 1px;

    a,
    span {
      color: #4f4f4f;
      right: 0;
      cursor: pointer;
    }

    i {
      font-weight: 900;
      transform: none !important;
    }

    .user {
      font-size: 17px;
      padding-right: 7px;
      position: relative;
      top: -1.5px;
    }

    .calendar {
      font-size: 20px;
      padding-right: 3px;
      position: relative;
      top: -0.5px;
    }

    .chat {
      font-size: 21px;
      position: relative;
      top: 1px;
    }
  }
}

.pointer {
  cursor: pointer;
}
