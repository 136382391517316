.timeDropdown {
  &:global(.ui.search.selection.dropdown) {
    padding: 1em 0.5em 0.5em;

    > :global(input.search) {
      height: 100%;
      padding: 0.75em 0.5em;
    }
  }

  &:global(.ui.search.dropdown > .text) {
    display: none;
  }
}
