:global(.ui.header) {
  &.header {
    font-weight: normal;
    line-height: 55px;
    margin: {
      top: 5rem;
      bottom: 0;
    }
  }

  &.subHeader {
    font-weight: normal;
    line-height: 26px;
    margin: {
      top: 0.5rem;
      bottom: 54px;
    }
  }
}

.content {
  width: 100%;
  overflow-y: auto;
  flex: 1;
  padding-bottom: 5rem;
}

.socHeader {
  margin: {
    top: 0.5rem !important;
    bottom: 1.2rem !important;
  }
}

.adHeader {
  margin: {
    top: 1.75rem !important;
    bottom: 0rem !important;
  }
}

.adSubHeader {
  margin: {
    top: 0.25rem !important;
    bottom: 1.2rem !important;
  }
}

.form {
  label:after {
    color: transparent !important;
  }
}

.signupForm {
  max-width: 26rem !important;
  margin-left: auto;
  margin-right: auto;
}

.loginForm {
  max-width: 22rem !important;
  margin-left: auto;
  margin-right: auto;
}

.checkboxField {
  label:after {
    color: rgba(0, 0, 0, 0.95) !important;
  }
}

.teamNameLabel {
  font-size: 0.875em;
  margin: 0 0 0.25rem 0 !important;
}

.teamName {
  margin-bottom: 0 !important;
}

.helper {
  display: block;
  margin: 1px 0 10px;
}

.teamMembers {
  margin-bottom: 20px;
}

.sectionMemberMatch {
  cursor: pointer;
  margin: 2px 0;
  overflow: hidden;
  padding: 0 10px;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:first-of-type {
    margin-top: 0;
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  &:hover,
  &.selected {
    background: rgba(#2096f3, 0.1);
  }
}

.sectionMemberMatchAvatar {
  display: inline-block;
  margin-right: 5px;
  vertical-align: top !important;

  i {
    line-height: 16px !important;
  }
}

.createAccount {
  padding: {
    top: 1.75rem;
    bottom: 0.5rem;
  }

  a {
    font-weight: 600;
    text-transform: capitalize;
  }
}

.passwordReset {
  padding: 0.5rem 0;

  a {
    font-weight: 600;
  }
}

.socialContainer {
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 0.25rem;
  padding: 20px;
  margin-left: auto;
  margin-right: auto;
  max-width: 29rem;

  :global(.ui.button) {
    padding: 0.935rem;
  }

  :global(.ui.button).adButton {
    background-color: #00aaf2 !important;
    padding: 0.5rem !important;

    img {
      height: 2.5rem;
    }
  }

  :global(.ui.three.column.grid) {
    margin-left: -7px !important;
    margin-right: -7px !important;
  }
}

// Text Classes
.regularText {
  font-size: 1.14rem !important;
  line-height: 1.09rem !important;
}

.smallText {
  font-size: 1rem !important;
  line-height: 1.21rem !important;
}

.boldText {
  font-weight: 600 !important;
}

.normalText {
  font-weight: normal !important;
}

:global(.ui.form .field) {
  &.legalAge {
    > label {
      margin-left: 20px;
      text-indent: -20px;

      input[type="checkbox"] {
        width: 20px;
        vertical-align: middle;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .form {
    padding: 1.2rem;
  }

  .signupForm {
    max-width: 24rem !important;
  }

  .loginForm {
    max-width: 24rem !important;
  }

  .socialContainer {
    max-width: 24rem;

    :global(.ui.button) {
      padding: 0.875rem 1.5rem 0.875rem !important;
    }
  }
}
