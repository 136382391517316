@import "../../theme/variables";
@import "../../theme/mixins";

.loadedContent {
  composes: loadedContent from global;
  display: flex;
  height: 100%;

  > * {
    display: flex;
    flex-direction: column;
  }
}

.container {
  flex: 1;
  display: flex !important;
  flex-direction: column;
  min-height: 80vh;
  margin-top: 20px;
  padding: 15px 0;

  h4:global(.ui.header) {
    font-size: 26px;
  }
}

.contentWrapper {
  flex: 1;
}

:global(.ui.header) {
  &.header {
    margin: 10px 0;
    font-size: 32px;

    @media (min-width: 769px) {
      margin: 30px 0;
      font-size: 48px;
    }
  }
}

.helper {
  display: block;
  margin: 10px 0;

  &:global(.field) {
    margin-top: 10px !important;
  }
}

.emailList {
  height: 35vh;
  overflow: auto;

  li {
    font-size: 24px;
    padding: 15px !important;
    color: gray;

    &:hover {
      color: black;
    }
  }
}

.sectionMemberMatch {
  cursor: pointer;
  margin: 2px 0;
  overflow: hidden;
  padding: 0 10px;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:first-of-type {
    margin-top: 0;
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  &:hover,
  &.selected {
    background: rgba($blue, 0.1);
  }
}

.sectionMemberMatchAvatar {
  display: inline-block;
  margin-right: 5px;
  vertical-align: top !important;

  i {
    line-height: 16px !important;
  }
}

.memberList {
  margin-top: 1rem;
}

.projectWrapper,
.toolsWrapper {
  input ~ label {
    font-size: 24px !important;
  }
}

.prevNext {
  margin-top: 30px;

  @media (max-width: 991px) {
    button {
      width: 100% !important;
      float: none;
      margin-bottom: 15px !important;
    }
  }
}

.creating,
.done {
  text-align: center;
  padding: 60px 0 !important;

  p {
    font-size: 24px;
    margin: 30px 0 !important;
  }
}

:global(.ui.list).defaultTools {
  > :global(.item) {
    padding-bottom: 10px;
  }
}

:global(.ui.list).optionalTools {
  > :global(.item) {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

@media (max-width: $tabletBreakpoint) {
  .mainModal:global(.ui.modal) {
    margin-left: 0 !important;
    margin-right: 0 !important;

    :global(.close.icon) {
      top: -0.5rem !important;
    }

    :global(.header) {
      padding: 0 !important;
      font-size: 18px !important;
    }

    :global(.content) {
      padding-left: 0 !important;
      padding-right: 0 !important;

      :global(.item .content) {
        padding-left: 10px !important;
      }

      :global(.ui.header),
      :global(.header) {
        font-size: 16px !important;
      }

      :global(.description) {
        font-size: 14px !important;
      }

      :global(.ui.form) {
        :global(.header) {
          font-size: 16px !important;
        }

        :global(.ui.button) {
          font-size: 12px;
          padding-left: 10px;
          padding-right: 10px;
        }

        :global(.ui.input) {
          font-size: 14px;
        }

        textarea {
          font-size: 14px;
        }
      }

      .creating p {
        font-size: 20px;
      }
    }

    .prevNext {
      :global(.ui.button) {
        font-size: 13px;
      }
    }
  }
}
