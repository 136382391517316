@import "../../theme/mixins";
@import "../../theme/variables";

.pill {
  align-items: center;
  border: solid 1px $lightgrey;
  border-radius: 14.5px;
  color: $grey;
  display: inline-flex;
  font-size: 13px;
  line-height: 1.23;
  margin: 2.5px 5px 2.5px 0;
  max-width: 100%;
  padding: 3.5px 7px;

  &:first-of-type {
    margin-top: 10px;
  }

  &:last-of-type {
    margin-right: 0;
  }

  &:hover {
    background-color: $blue;
    color: $white;
  }

  &:global(.clickable) {
    cursor: pointer;
  }

  :global(.avatar) {
    margin-right: 5px;
  }

  :global(.close) {
    font-size: 12px;
    font-weight: $fontWeightNormal;
    margin: 0 0 0 6px;
  }

  :global(.text) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
