@import "../../theme/variables";
@import "../../theme/mixins";

$blue: #0d92fe;
$black: #212121;
$darkergray: #394044;
$gray: #e0e0e0;
$darkgray: #757575;
$white: #fff;
$warningColor: #573a08;
$skillTagWidth: 12.8rem;
$skillTagClose: 12rem;
$notificationSuccess: #53b2a2;
$notificationError: #f45f53;

$palette: (#eb5757, #f2994a, #f2c94c, #219653, #56ccf2, #2d9cdb, #685ace, #4f4f4f);

:global(.loadedContent) {
  background-color: $white;
  display: flex;
  flex: 1 1;
  flex-direction: column;
  height: 100%;
  max-width: 100%;
  overflow-x: hidden;
  position: relative;
  width: 100%;
}

.container {
  flex: 1;
  overflow: auto;
  background-color: $white;
}

.search {
  margin-top: 30px;
  margin-bottom: 30px;
  padding-left: 15px;
}

.sidebar {
  box-shadow: none !important;
  background-color: #1b1c1d;
}

.actions {
  padding: 5px 0;
  background-color: darken(#eef3f7, 5%);
}

.mt20 {
  position: relative;
  margin: 1.625rem 1.25rem !important;

  &:after {
    clear: both;
    display: block;
    content: "";
  }
}

.mt40 {
  position: relative;
  margin-top: 40px !important;
}

.navigation {
  background: transparent !important;
  border-bottom: 1px solid $gray !important;
  overflow-y: hidden;
  width: 100%;

  &:global(.ui.menu) {
    margin: 0 !important;
  }

  :global(.item) {
    padding: 0 1.25rem !important;

    a,
    span {
      color: $darkgray !important;
      cursor: pointer;
      font-size: 0.9375rem !important;
      font-weight: 600 !important;
      line-height: 1.5rem !important;
      padding-top: 1rem !important;
      padding-bottom: 1rem !important;

      &.activeMenu {
        color: $darkergray !important;
        padding-bottom: 0.8125rem !important;
        border-bottom: 3px solid #212121 !important;

        @media screen and (max-width: 400px) {
          padding-bottom: 4px;
        }
      }
    }
  }

  .rightMenu {
    :global(.item) {
      vertical-align: middle;
      height: 100%;
      padding-left: 0 !important;

      :global(.button) {
        font-size: 0.9rem !important;
      }
    }
  }
}

.noBoxShadow {
  box-shadow: none !important;
}

.noBorder {
  border: none !important;

  tr,
  td,
  th {
    border-top: none !important;
    border-bottom: none !important;
  }
}

.noMargin {
  margin: 0 !important;
}

.noPaddingTableRow {
  th,
  td {
    &:first-child {
      padding-left: 0 !important;
    }

    &:last-child {
      padding-right: 0 !important;
    }
  }
}

.leftToggleLabel {
  cursor: pointer;
  margin-right: 1rem;
}

.twoLabeledToggle {
  display: inline-block;
}

.updateButton {
  display: inline;
}

.closeButton {
  cursor: pointer;
  float: right;
  margin-bottom: 10px;
}

.avatarInput {
  display: none;
}

.avatarUploader {
  display: inline-block;

  .avatarImageContainer {
    display: inline-block;
  }

  .avatarButton {
    margin-left: 1.5rem;
  }

  .editor {
    cursor: pointer;
    position: relative;
    display: inline-block;

    &:hover {
      &::after {
        content: "\f303";
        font-family: "Icons";
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        background-color: rgba(255, 255, 255, 0.5);
        border-radius: 50%;
        color: rgba(255, 255, 255, 0.7);
        font-size: 20px;
      }
    }
  }
}

.avatarLoadingContainer {
  top: 0;
  position: absolute;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.5);
  color: #fff;
  z-index: 2;
  text-align: center;
}

.headerWrapper {
  border-bottom: 1px solid rgba(34, 36, 38, 0.15);
  flex: 0;

  .headerPreviewMessage {
    background-color: #424242;

    .previewMessage {
      background: transparent;
      box-shadow: none;
      color: $white;
      font-size: 0.85rem;

      a {
        color: $white;
        font-weight: 600;
        cursor: pointer;
      }

      span {
        margin-right: 0.75rem;
      }

      p {
        opacity: 1 !important;
      }

      .btnContainer {
        display: inline-block;
        float: right;
        margin: 0 !important;
      }
    }
  }

  .header {
    padding: 30px 0;
  }

  .noneditableHeader {
    font-size: 30px !important;
    font-weight: 400;
    margin-bottom: 0;
    display: inline-block;
    border: 1px solid transparent;
    border-radius: 3px;
    margin-top: -1px;
    margin-left: -1px;

    .name {
      display: inline-block;
      vertical-align: middle;
    }

    small {
      display: none !important;
      vertical-align: middle;
    }

    &.editableHeader:hover {
      border-color: gray;

      small {
        display: inline-block !important;
      }
    }
  }

  .displayNewLine {
    white-space: pre-line;
  }

  .headerMain {
    display: inline-block;
    margin: 0 !important;
    vertical-align: middle;
    font-size: 1.25rem !important;
    line-height: 1.6875rem !important;
    font-weight: 600 !important;
  }

  .btnContainer {
    display: inline-block;
    float: right;
    margin: 1.125rem 1.25rem 1.0625rem 0;
  }

  .headerGrid {
    margin: 0.5rem 0 !important;

    .headerDescriptionContainer {
      padding: 1.25rem 1.25rem 1.5625rem 1.25rem !important;

      @media screen and (min-width: 768px) {
        &.previewMode,
        &.teamMode {
          border-right: 2px solid $gray;
        }
      }
    }

    .headerAvatar {
      display: table-cell;
      vertical-align: middle;
    }

    .previewBookDetails {
      tr,
      td {
        border: none;
        line-height: 0.9rem;

        .rate {
          color: $primaryColor;
          font-weight: 600;
        }
      }

      td:first-child {
        width: 30%;
      }

      .btnContainer {
        float: none;
        margin: 0;

        a {
          margin-right: 1.5rem;
        }
      }
    }

    .headerDescription {
      margin: 0 !important;
      display: table-cell;
      vertical-align: middle;
      padding-left: 1rem;

      .name {
        font-size: 1.5rem !important;
        line-height: 2.375rem !important;
        font-weight: 600 !important;
        margin-bottom: 0 !important;
      }
    }
  }

  .headerLink {
    @include buttonLink($black, $text-decoration: none);
  }

  &.updateHeader {
    text-align: center;
    color: $primaryColor;
    font-weight: 600;
    line-height: 3.125rem;
    background-color: #ffffff;
    align-items: center;
    flex: 0 0 3.125rem;
    display: flex;
    padding: 0 1.25rem;

    .headerLabel {
      flex: 1 1;
      width: 1px;
    }

    .btnContainer {
      margin: inherit !important;
    }

    .headerLink {
      font-size: 0.85rem !important;
      font-weight: 400 !important;
    }
  }

  .avatarDisplay {
    display: inline-block;
    margin: 0.8125rem 0.625rem 0.75rem 1.25rem;
  }

  .featuredSkillsContainer {
    margin: 0 !important;

    :global(.column) {
      padding-top: 0 !important;
    }
  }
}

.industries {
  display: flex;
  align-items: center;

  :global(.field) {
    flex: 1;
  }

  .btnContainer {
    padding: 0 10px;
  }
}

.navigationBar {
  flex: 0;
  position: sticky;
  top: 0;
  background-color: #ffffff;
  z-index: 2000;
}

.avatar {
  border: 3px solid rgba(255, 255, 255, 0.7);
  border-radius: 100%;
}

.profileHeading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;

  > h2 {
    margin-bottom: 0;
    font-size: 20px;
    line-height: 24px;
    color: $black;
  }
}

.profileSubHeading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;

  > h5 {
    margin-bottom: 0;
  }

  .inlineChecboxes {
    margin-bottom: 0 !important;
  }
}

.profileDivider {
  margin-top: 0;
  margin-bottom: 20px;
}

.basicBtn {
  display: inline-block !important;
}

:global(.ui.button).blueBtn {
  color: $white;
  border: 1px solid $primaryColor;
  background: $primaryColor;
  box-shadow: none;
  transition: 0.2s ease;

  &:hover,
  &.filled {
    color: $white;
    background: darken($primaryColor, 5%);
    transition: 0.2s ease;
  }
}

:global(.ui.tiny.form) :global(.ui.button).blueBtn {
  height: 35px;
  padding: 0.75em 0.9375em;
  font-size: 12px;
}

.availabilityState {
  color: #fff !important;
  border-radius: 25px !important;
  font-size: 12px !important;
  padding: 5px 16px !important;
  margin-top: 5px !important;
}

.privateInfo {
  color: $blue;
  font-size: 12px;
}

.fieldIcon {
  background-color: $blue !important;
  color: $white !important;

  & > i {
    margin: 0 !important;
  }
}

.googleCalendarContainer {
  margin-bottom: 1em;

  & > p {
    margin-bottom: 0;
  }
}

@keyframes ellipsis {
  to {
    width: 1.25rem;
  }
}

.availabilityContainer {
  display: flex;
  height: 100%;

  @media (max-width: 991px) {
    display: block;
  }

  .availabilitySidebar {
    border-right: 1px solid $gray;
    overflow-x: hidden;
    overflow-y: auto;
    width: 285px;
    padding: 20px;
    height: 100%;

    .availabilityCalendarWidget {
      margin-left: -1.3rem;
    }

    .googleCalendarContainer {
      margin-bottom: 1em;

      & > p {
        margin-bottom: 0;
      }
    }

    .availabilitySection {
      h5 {
        display: inline-block;
        font-weight: 600;
        line-height: 24px;
        margin-bottom: 0.5rem;
      }

      .accountsHeader {
        :global(.ui.button) {
          box-shadow: none;
          float: right;
          margin: 0;
          padding: 0.25rem 0;
        }
      }

      .availabilitySummary {
        p {
          &.summaryHeader {
            font-weight: 600;
            margin-bottom: 0;
          }
        }

        .hoursSummary {
          margin-top: 1rem;
          width: 135px;
          position: relative;

          .hourCount {
            color: #685ace;
            font-size: 40px;
            line-height: 40px;
          }
          .requiredHours {
            position: absolute;
            bottom: 0;
            right: 0;
            font-size: 14px;
          }
        }

        .hoursSummaryText {
          margin: 6px 0 16px 0;
          font-size: 14px;
        }

        .hourRow {
          display: flex;
          .hourBlock {
            flex: 1;
            .hours {
              font-size: 30px;
              line-height: 38px;
              color: #4f4f4f;
            }

            .hoursText {
              font-size: 14px;
              color: #212121;
            }
          }
        }
      }

      .showUnbillable {
        margin: 20px 0 16px 0;
        font-size: 14px;
      }

      .calendarViewHeader {
        font-weight: 600;
        line-height: 24px;
        margin-bottom: 0.5rem;
      }

      .calendarView {
        .checkBox {
          margin-bottom: 0.5rem;

          span {
            font-size: 14px;
          }
        }

        .projectsList {
          width: 200px;
          margin-left: 24px;
          position: relative;

          .checkBox {
            .colorPicker {
              cursor: pointer;
              display: inline-block;
              width: 12px;
              height: 12px;
              margin-right: 12px;
              border-radius: 50%;
              @for $i from 1 through 8 {
                &.colorPicker--#{$i} {
                  background-color: nth($palette, $i);
                }
              }
            }
          }

          .projectsSearch {
            width: 175px;
            font-size: 14px;
            line-height: 20px;
            padding-left: 24px;
            border: none;
            border-bottom: 1px solid #c4c4c4;
            background-image: url("./components/assets/search.svg");
            background-repeat: no-repeat;
            background-position: 6px 7px;
            color: #212121;
            margin-bottom: 15px;

            ::placeholder {
              color: #bdbdbd;
              font-size: 14px;
            }
            &:focus {
              outline: none;
            }
          }
        }
      }

      :global(.ui.checkbox) {
        display: block;
        margin-bottom: 0.5rem;
        font-size: 14px;
      }
    }
  }

  .availabilityAccordion {
    margin: 1rem;

    :global(.ui.checkbox) {
      margin-right: 1.5rem;
      font-size: 14px;
    }

    :global(.title) {
      :global(.ui.button) {
        box-shadow: none;
        float: right;
        margin: 0;
        padding: 0.25rem 0;
      }
    }

    :global(.content) {
      padding: 0.5rem 1.5rem 1rem !important;
    }
  }

  .accountGroup {
    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }

    .accountGroupHeader {
      p {
        display: inline-block;
      }

      .accountGroupActions {
        float: right;

        :global(.ui.button) {
          box-shadow: none;
          font-size: 14px;
          margin-right: 0.5rem;
          padding: 0.25rem 0;

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }

    p {
      font-size: 14px;
      margin-bottom: 0;

      &.accountHeader {
        font-weight: 600;
      }

      &.syncingMessage {
        &:after {
          overflow: hidden;
          display: inline-block;
          vertical-align: bottom;
          -webkit-animation: ellipsis steps(4, end) 900ms infinite;
          animation: ellipsis steps(4, end) 900ms infinite;
          content: "\2026";
          width: 0px;
        }
      }
    }

    > :global(.ui.checkbox) {
      display: block;
      margin-bottom: 0.5rem;
      font-size: 14px;
    }
  }

  .availabilityCalendar {
    flex: 1;
    height: 100%;

    @media (max-width: 991px) {
      max-width: 100%;
      padding-left: 0;
    }

    .calendarContainer {
      // height: calc(100vh - 277px) !important;
      height: 100% !important;

      :global(.rbc-toolbar) {
        padding: 10px 20px;
      }

      .headerBtn {
        background: transparent !important;
        box-shadow: none !important;
        color: $black;
      }

      :global(.rbc-events-container) {
        margin-right: 0;
      }

      .calendarEvent {
        background-color: $gray !important;
        border-color: $white !important;
        border-radius: 2px;
        color: $black;
        font-size: 12px;
        padding: 0;

        @for $i from 1 through 8 {
          &.calendarEvent--#{$i} {
            border-color: nth($palette, $i) !important;
            background-color: rgba(nth($palette, $i), 0.2) !important;

            &:hover {
              background-color: rgba(nth($palette, $i), 0.3) !important;
            }
          }
        }

        :global(.rbc-event-label) {
          display: none;
        }

        :global(.rbc-event-content) {
          padding: 0 5px;

          .eventTitle {
            margin-bottom: -2px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .eventTime {
            font-size: 90%;
          }
        }

        &.calendarEventAvailable {
          background-color: $white !important;
          border-color: $gray !important;

          &.calendarEventInstantBook::before {
            background-color: $primaryColor;
            content: "";
            display: block;
            height: 3px;
            width: 100%;
          }
        }
      }
    }
  }
}

.availabilityAddModal {
  position: fixed;
  top: 5%;
  @media (min-width: 420px) {
    width: 523px !important;
  }
  :global(.close) {
    top: 0 !important;
    right: 0 !important;
    color: #212121 !important;
    font-size: 18px !important;
    line-height: 21px;
  }

  .modalContent {
    padding: 1rem;

    .modalTab {
      border: none;
      box-shadow: none;
      padding: 0;

      .section {
        margin-bottom: 0.5rem;
        display: flex;
        align-items: center;

        &.hidden {
          display: none;
        }

        .icon {
          margin: 0 !important;
          color: #bdbdbd;
          position: absolute;
          font-weight: bold;
          left: 0px;
        }

        span {
          .icon {
            margin-top: -7px !important;
            color: #bdbdbd;
            position: absolute;
            font-weight: bold;
            left: 0px;
          }
        }

        .label {
          margin: 0 !important;
          margin-left: 30px !important;
          width: 90px;
          min-width: 90px;

          .teamChannelInfoIcon {
            margin-left: 6px !important;
            font-size: 12px !important;
          }

          &.addGuests {
            align-self: flex-start;
          }

          &.hasGuests {
            margin-top: 12px !important;
          }
        }

        .recurrenceFields {
          display: flex;
          align-items: center;
          width: 100%;

          .allDay {
            margin-bottom: 0px;
            width: 120px;
            margin-right: auto;
          }

          .recurrenceInput {
            margin-left: auto;
          }
        }

        .input {
          margin-bottom: 0 !important;
          width: 100%;

          &:focus-within ~ .icon {
            color: #424242 !important;
          }

          &.eventFilterUser {
            input {
              border: none;
              border-radius: unset;
              border-bottom: solid $gray;
              padding-left: 0;
            }
          }
        }

        .checkbox {
          margin: 4px 0px;

          &:focus-within ~ .icon {
            color: #424242 !important;
          }
        }

        .radioBtn {
          margin-bottom: 0px !important;
          &:focus-within ~ .icon {
            color: #424242 !important;
          }
        }

        .timezoneDropdown {
          margin-top: 5px;
        }

        &.singleDatePicker {
          :global(.SingleDatePicker) {
            vertical-align: middle;
            width: 100%;

            &:focus-within ~ .icon {
              color: #424242 !important;
            }

            :global(.SingleDatePickerInput) {
              width: 100%;

              :global(.DateInput) {
                width: 100%;
                input {
                  &:disabled {
                    color: #d3d3d3;
                    border-color: #f0f0f1;
                    background-color: #ffffff;
                  }
                  font-weight: $fontWeightNormal;
                }
              }
            }

            :global(.SingleDatePicker_picker) {
              z-index: 10;

              :global(.CalendarDay__default) {
                border-color: $borderColor;
              }

              :global(.CalendarDay__selected) {
                background-color: $primaryColor;
                border-color: $borderColor;
                color: $white;

                &:active,
                &:hover {
                  background-color: $primaryColor;
                }
              }
            }
          }
        }
      }

      .guestsContainer {
        display: flex;
        flex-direction: column;
        width: 100%;

        .addGuestCheckbox {
          margin: 4px 0px;
        }

        .selectAllCheckbox {
          margin: 4px 10px 0px 0px;
          padding: 10px 0px 5px 0px;
        }

        .eventGuests {
          max-height: 140px;
          overflow-y: auto;
          margin-top: 8px;

          .guestAttendance {
            font-weight: 900;
            line-height: 1.1;
            margin-right: 10px;

            &.optional {
              font-weight: normal;
            }

            &.disabled {
              opacity: 0.5;
            }
          }

          .guestCheckbox {
            margin: 4px 10px 0px 0px;
          }

          .guestName {
            margin-left: 6px;
          }

          .guestOptionalText {
            font-weight: lighter;
            margin-left: 4px;
          }
        }
      }

      .formActions {
        padding-top: 1rem;
        text-align: right;

        button {
          padding: 12px 14px;
        }
      }
    }
  }
}

.teamChannelInfoPopup {
  font-size: 14px !important;
  text-align: center !important;
}

.availabilityForm {
  .eventFormFields {
    width: 492px !important;

    .formField {
      margin-bottom: 0.5rem;
      display: flex;
      align-items: center;

      &.hidden {
        display: none;
      }

      .label {
        margin: 0 !important;
        width: 120px;
        min-width: 120px;

        &.addGuests {
          align-self: flex-start;
        }

        &.hasGuests {
          margin-top: 12px !important;
        }
      }

      .recurrenceFields {
        display: flex;
        align-items: center;
        width: 100%;

        .allDay {
          margin-bottom: 0px;
          width: 120px;
          margin-right: auto;
        }
      }

      .input {
        margin-bottom: 0 !important;
        width: 100%;
      }

      .timezoneDropdown {
        margin-top: 5px;
      }
    }

    .guestsContainer {
      display: flex;
      flex-direction: column;
      width: 100%;

      .addGuestCheckbox {
        margin: 4px 0px;
      }

      .guests {
        max-height: 140px;
        overflow-y: auto;
        margin-top: 8px;

        .guestCheckbox {
          margin-bottom: 0;
          margin-right: 12px;
          margin: 4px 12px 0px 0px;
        }

        .guestName {
          margin-left: 6px;
        }
      }
    }
  }

  .btnContainer {
    float: right;
    padding-bottom: 1rem;
  }
}

.deleteEventModal {
  border-radius: 0px !important;
  max-width: 520px !important;

  :global(.close) {
    top: 0 !important;
    right: 0 !important;
    color: #212121 !important;
    font-weight: 900;
    font-size: 18px !important;
    line-height: 21px;
    padding-top: 18px;
    padding-right: 5px;
  }

  .modalContent {
    text-align: left;

    .heading {
      color: #212121;
      font-size: 24px;
      line-height: 30px;
      letter-spacing: -0.34px;
      margin-top: 0px;
      margin-bottom: 7px;
    }

    .content {
      font-size: 14px;
      line-height: 18px;
      color: #4d4d4d;
      letter-spacing: -0.0373333px;
      margin: 0px;
    }

    .actionsContainer {
      padding-top: 20px;
      padding-bottom: 5px;

      .action {
        font-size: 14px;
        line-height: 21px;
        font-weight: normal;
        margin: 0 8px 8px;

        &.delete {
          background-color: $deleteColor;
          color: $white;
        }

        &.cancel {
          background-color: $white;
          border: 1px solid #d3d3d3;
          color: #4d4d4d;
        }
      }
    }
  }
}

.warningOnCloseModal {
  border-radius: 0px !important;
  max-width: 520px !important;

  :global(.close) {
    top: 0 !important;
    right: 0 !important;
    color: #212121 !important;
    font-weight: 900;
    font-size: 18px !important;
    line-height: 21px;
    padding-top: 18px;
    padding-right: 5px;
  }
  .modalContent {
    text-align: left;

    .heading {
      color: #212121;
      font-size: 24px;
      line-height: 30px;
      letter-spacing: -0.34px;
      margin-top: 0px;
      margin-bottom: 7px;
    }

    .content {
      font-size: 14px;
      line-height: 18px;
      color: #4d4d4d;
      letter-spacing: -0.0373333px;
      margin: 0px;
    }

    .actionsContainer {
      padding-top: 20px;
      padding-bottom: 5px;

      .action {
        font-size: 14px;
        line-height: 21px;
        font-weight: normal;
        margin: 0 8px 8px;

        &.confirm {
          background-color: $deleteColor;
          color: $white;
        }

        &.cancel {
          background-color: $white;
          border: 1px solid #d3d3d3;
          color: #4d4d4d;
        }
      }
    }
  }
}

.syncCalendarModal {
  :global(.content) {
    padding: 0 1.5rem 2rem !important;
    .syncCalendarAccount {
      h4 {
        display: inline-block;
        margin-bottom: 0.5rem;
      }

      :global(.ui.button) {
        float: right;
      }
    }
  }
}

.calendarSyncSelection {
  width: 200px !important;

  :global(.content) {
    padding: 0 1.5rem 2rem !important;

    .selectGoogle {
      width: 150px;
      color: $white;
      margin-bottom: 1rem;
      background-color: #dd4b39;
    }

    .selectOutlook {
      width: 150px;
      color: $white;
      background-color: #0078d7;
    }
  }
}

.dayBtn {
  width: 40px;
  height: 40px;
  color: $white !important;
  font-weight: normal !important;

  &.active {
    background-color: $blue !important;
  }
}

.avatarContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 2.5rem;
}

.colorButtons {
  text-align: center;
  display: inherit !important;
}

.avatarEditor {
  text-align: center;

  .avatarContainer {
    margin-bottom: 0.5rem;
  }

  .colorButtons {
    margin-bottom: 1.5rem !important;
  }
}

.buttonContainer {
  text-align: center;
}

.colorPickersContainer {
  text-align: center;

  .colorPickerContainer {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .colorButtons {
    margin-bottom: 1.5rem !important;
    margin-top: 1rem !important;
  }
}

.heroBanner {
  background: $white;
  border-bottom: 1px solid $gray;
  padding: 1.375rem 0;
  text-align: center;

  h1 {
    font-size: 1.5rem !important;
    line-height: 1.625rem !important;
    font-weight: normal !important;
    margin-bottom: 0;
  }
}

.settingsContainer {
  margin-top: 2.5rem !important;
  max-width: 600px;
  margin: auto;

  .settingsCheckbox {
    display: inline-block;
    float: right;
  }

  .settingsField {
    padding: 0.5rem 0;
  }
}

.checkboxContainer {
  :global(.ui.checkbox) {
    margin: 0 0 1rem;
  }
}

.basicInfoContainer {
  position: relative;
  margin: 1.625rem 1.25rem !important;

  .personalInfo {
    padding: 0 0.9375rem 0 0.3125rem !important;

    .linkedAccounts {
      padding-bottom: 1.25rem;
    }

    @media screen and (min-width: 768px) {
      border-right: 1px solid $gray;
    }
  }

  .workInfo {
    padding: 0 0.9375rem 0 0.3125rem !important;

    @media screen and (min-width: 768px) {
      padding: 0 0 0 1.5625rem !important;
    }
  }

  .mainLabel {
    font-size: 0.9375rem;
    font-weight: 600;
    line-height: 1.125rem;
    color: $darkgray;
    margin-bottom: 0.75rem !important;
  }

  .mainContent {
    font-size: 1.25rem;
    line-height: 1.6875rem;
    color: $black;
    margin-bottom: 0.3125rem !important;
  }

  .secondaryContent {
    font-size: 0.9375rem;
    line-height: 1.4375rem;
    color: $darkgray;
  }

  .tertiaryContent {
    font-size: 0.9375rem;
    line-height: 1.125rem;
    color: $black;
    margin-bottom: 0.5625rem !important;
  }

  .infoContent {
    font-size: 0.875rem;
    color: $black;
    margin-bottom: 0.625rem !important;

    a {
      color: $black !important;
    }

    :global(.icon) {
      color: $darkgray;
    }
  }

  .sectionDivider {
    height: 2px;
    color: $gray;
    margin: 1.25rem 0;
  }

  .listItem {
    margin-bottom: 1.5rem !important;

    :global(.icon) {
      color: #424242 !important;
      line-height: 1.6875rem !important;
    }

    img {
      max-width: 100%;
    }
  }

  .hiddenContactInfo {
    text-align: center;
    background-color: #f5f5f5;
    font-size: 0.875rem;
    padding: 1.25rem 0.75rem;
  }

  .rates {
    tr,
    td {
      border-color: transparent !important;
    }
  }

  .noInfo {
    padding: 1rem;
    background-color: #f5f5f5;

    h2 {
      color: #757575;
      font-size: 1.35rem;
      text-align: center;
    }
  }

  .empty {
    padding: 30px;
    background-color: #ffffff;
    text-align: center;
    vertical-align: middle;

    img {
      display: block;
      margin: 0 auto;
      max-width: 100%;
    }

    h2 {
      font-size: 32px;
      color: #212121;
      line-height: 38px;
      margin-bottom: 10px;
      text-align: center;
    }

    p {
      font-size: 20px;
      line-height: 24px;
      color: #757575;
      text-align: center;
    }

    button {
      margin: 20px auto 0 auto;
      display: block;
    }
  }

  .ratesContainer {
    th {
      color: #757575 !important;
    }

    .suggestedRate {
      display: inline-block;
      padding: 0.75em 0.9375em;
      width: 100%;
      background-color: #efeefa;
      border-radius: 4px;
    }
  }

  .ratesCheckbox {
    display: inline-block;
    float: right;
  }
}

:global(.ui.card).skillCard {
  align-items: center;
  border-radius: 4px;
  flex-direction: row;
  min-height: 80px;
  transition: border 0.2s ease;

  @media (min-width: 481px) {
    box-shadow: none;
  }

  &:hover {
    box-shadow: 0 1px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5;
    transition: box-shadow 0.2s ease;
    transform: none;
  }

  .icon {
    background-color: lighten($blue, 30%);
    border-radius: 50% !important;
    margin: 0 5px 0 10px;
    min-height: 50px;
    min-width: 50px;
    position: relative;

    .eloIcon {
      bottom: 0;
      position: absolute;
      right: -9px;
    }
  }

  :global(.content) {
    border-top: 0;
    flex: 1;
    font-size: 14px;
    line-height: 20px;
  }
}

.membersListContainer {
  :global(.ui.card).member {
    transition: border 0.2s ease;

    @media (min-width: 481px) {
      box-shadow: none;
    }

    &:hover {
      box-shadow: 0 1px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5;
      transition: box-shadow 0.2s ease;
      transform: none;
    }

    .avatar {
      margin: auto;
      width: 110px;
      padding: 1rem;
    }

    :global(.header) {
      font-size: 16px;
    }

    > :global(.content),
    > :global(.extra) {
      margin: 0;
      border-top: none !important;

      :global(.description) {
        font-size: 14px;
        margin: 0;
        max-height: 42px;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 20px;
        min-height: 21px;
      }
    }

    .skills {
      padding: 0 1rem 1rem;
    }

    .profileLink {
      padding-top: 2rem;
      font-size: 14px;
      color: $black;
      font-size: 16px;
      font-weight: $fontWeightBold;
    }
  }
}

.formContainer {
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1rem;

  @media (max-width: 767px) {
    padding-left: 0;
    padding-right: 0;
  }

  .ratesCheckbox {
    display: inline-block;
    float: right;
  }

  .teamRatesContainer {
    tr:first-child {
      td.label {
        padding: 0 0 0.5rem !important;
      }
    }

    td {
      padding: 0 !important;

      &.label {
        padding: 1rem 0 0.5rem !important;
      }

      &.to {
        text-align: center;
      }

      &.message {
        padding: 0.5rem 0 !important;
      }
    }
  }

  .alertContainer {
    margin-bottom: 1rem;
  }
}

.headerLabelContainer {
  :global(.header) {
    display: inline-block;
    margin-top: 0 !important;
  }

  .btnContainer {
    float: right;
  }

  &:after {
    clear: both;
    display: block;
    content: "";
  }
}

.profileForm {
  &.personalInfoForm,
  &.teamForm {
    .avatarUploader {
      margin-bottom: 1rem;
    }
  }

  .addBtn {
    clear: both;

    :global(.button) {
      box-shadow: none !important;
    }
  }

  .btnContainer {
    clear: both;
    float: right;
    padding-bottom: 1rem;

    .deleteBtn {
      cursor: pointer;
    }

    :global(.button.basic).cancel {
      background-color: white !important;

      &:hover {
        background-color: white !important;
      }
    }
  }

  &:after {
    clear: both;
    display: block;
    content: "";
  }

  &.unclear:after {
    clear: none !important;
  }

  .itemHeader {
    text-transform: uppercase;
    font-weight: 600;
    border-bottom: 1px solid $gray;
    margin: 1rem 0;

    &:after {
      clear: both;
      display: block;
      content: "";
    }

    .btnContainer {
      padding-bottom: 0;
    }
  }

  .private {
    label {
      :global(.ui.dropdown) {
        float: right;
      }
    }
  }

  .fieldInfo {
    margin-bottom: 1rem;
    :global(.field) {
      margin-bottom: 0 !important;
    }
  }

  .warning {
    color: $warningColor;
  }
}

.teamMembersList {
  &:global(.ui.container) {
    max-width: 600px !important;
  }

  .header {
    font-size: 1.625rem;
    display: block;
    margin-top: 1.875rem;
    margin-bottom: 1rem;

    &.subsection {
      font-size: 20px;
      margin-bottom: 10px;
    }
  }

  .sectionHeaderLine {
    border-color: #e0e0e0;
    border-width: 0.5px;
    margin-top: 6px;
  }

  .sectionMemberMatch {
    cursor: pointer;
    margin: 2px 0;
    overflow: hidden;
    padding: 0 10px;
    text-overflow: ellipsis;
    white-space: nowrap;

    &:first-of-type {
      margin-top: 0;
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    &:hover,
    &.selected {
      background: rgba($blue, 0.1);
    }
  }

  .sectionMemberMatchAvatar {
    display: inline-block;
    margin-right: 5px;
    vertical-align: top !important;

    i {
      line-height: 16px !important;
    }
  }

  .btnContainer {
    float: right;
    margin: 1rem 0;

    i {
      cursor: pointer;
    }
  }

  .addMembers {
    margin-bottom: 1.875rem;

    &:after {
      clear: both;
      display: block;
      content: "";
    }
  }

  .memberItem {
    padding: 0.65rem 0 !important;
  }

  .memberAvatar {
    display: inline-block !important;
    margin-right: 1rem;
  }

  .memberInfo {
    display: inline-block;
    vertical-align: middle;
  }

  .saveMembersForm {
    &:after {
      clear: both;
      display: block;
      content: "";
    }
  }

  .sectionMemberItemDescription {
    flex: 1;
    margin-left: 8px;
  }

  .sectionMemberItemDescriptionDate {
    color: $grey;
    font-size: 14px;
  }

  .sectionMemberItemDescriptionName {
    font-weight: $fontWeightBold;
  }

  .sectionMemberType {
    color: $grey;
    margin-right: 15px;
    min-height: 40px !important;
    min-width: 125px !important;

    &.dirty {
      border-color: $black !important;
      color: $black !important;
    }
  }

  .sectionRow {
    max-width: 600px;
  }

  .sectionRowContent {
    align-items: center;
    display: flex;
    margin-bottom: 10px;
  }

  .sectionRowContentLink {
    margin-bottom: 5px;
  }

  .sectionRowHeader {
    display: flex;
    margin-bottom: 5px;
  }

  .sectionRowLink {
    margin-bottom: 10px;
  }

  .sectionRowMember {
    margin-bottom: 16px;
  }

  .sectionCancel,
  .sectionRedirect,
  .sectionRemove {
    background: none !important;
    color: rgba($black, 0.5) !important;
    padding: 0 !important;

    &.solid i {
      font-weight: 900;
    }

    &:global(.ui.disabled) {
      background: none !important;
    }
  }

  .sectionCancel {
    color: $black !important;
  }

  .sectionColumnAction {
    width: 20px;
  }
}

.buttonLink {
  @include buttonLink($grey, $darkgrey);
  font-weight: 600 !important;

  &.red {
    color: #f45553 !important;
  }
}

.formContainer {
  padding-top: 1rem;
}

.deleteList {
  text-align: left;
}

.skillsContainer {
  margin: 1rem 0 !important;

  .skillRatingRow {
    &:not(:nth-last-child(-n + 2)) {
      border-bottom: 1px solid $gray;
    }
  }

  .skillRatingContainer {
    min-height: 4rem;

    .targetContainer {
      min-height: 4rem;
      display: block;

      span {
        display: block;
        width: $skillTagWidth;
        margin: 0 0.3rem;
      }

      div {
        display: inline-block;
      }
    }

    .skillItemContainer {
      margin: 0.3rem !important;

      i {
        z-index: 1;
        position: absolute;
        margin-left: $skillTagClose;
        margin-top: 0.5rem;
        cursor: pointer;
      }
    }

    .skillItem {
      border-radius: 150px;
      cursor: pointer;
      background: #f5f5f5;
      width: $skillTagWidth;

      .skillItemLabel {
        width: 80%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: inline-block;
      }
    }
  }

  .skillRatingLabel {
    :global(.ui.header) {
      height: 100%;

      &:before {
        content: "";
        display: inline-block;
        vertical-align: middle;
      }
    }
  }
}

.filterForm {
  width: 25%;

  :global(.field) {
    width: 100%;
  }
}

.teamsListContainer {
  :global(.ui.card).team {
    transition: border 0.2s ease;

    @media (min-width: 481px) {
      box-shadow: none;
    }

    &:hover {
      box-shadow: 0 1px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5;
      transition: box-shadow 0.2s ease;
      transform: none;
    }

    .avatar {
      margin: auto;
      width: 110px;
      padding: 1rem;
    }

    :global(.header) {
      font-size: 1rem !important;
    }

    > :global(.content) {
      padding: 0.5rem !important;
    }

    > :global(.content),
    > :global(.extra) {
      margin: 0;
      border-top: none !important;

      :global(.description) {
        font-size: 0.875rem !important;
        margin: 0;
        max-height: 42px;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 1.25rem !important;
        min-height: 21px;
      }
    }

    .skills {
      padding: 0 1rem 1rem !important;

      .skillsLink {
        cursor: pointer;
      }
    }

    .profileLink {
      color: $black;
      font-size: 16px;
      font-weight: $fontWeightBold;
      cursor: pointer;
    }
  }
}

.projectContainer {
  th {
    text-transform: uppercase !important;
    color: #757575 !important;
    font-size: 0.75rem;
    border-bottom: none !important;
  }

  td {
    font-size: 0.9rem;
  }

  .archived {
    tr:nth-child(2) {
      td {
        border-top: none !important;
      }
    }
  }

  .clientAvatar {
    display: inline-block;
    margin-right: 0.75rem;
  }

  .projectActions {
    :global(.item).actionItem {
      padding: 0 !important;

      span {
        display: block;
        padding: 0.6875rem 1.125rem !important;
      }
    }
  }
}

.projectShowArchived {
  @include buttonLink();
  font-weight: 600;
}

.notification {
  align-items: center;
  background-color: $darkgrey;
  color: $white;
  display: flex;
  font-size: 0.875rem;
  justify-content: space-between;
  padding: 0 1rem;
  width: 100%;
  z-index: $zIndexNotification;
  line-height: 3rem;

  &.error {
    background-color: $notificationError !important;
  }

  &.success {
    background-color: $notificationSuccess !important;
  }
}

.notificationButton {
  background: none !important;
  color: $white !important;
  margin-left: 10px !important;
  padding: 0 !important;

  &:hover {
    opacity: 0.75;
  }

  &:active {
    opacity: 0.5;
  }
}

.mainContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  position: relative;

  .contentWrapper {
    overflow: auto;
    flex: 1;
  }
}

.deleteContainer {
  height: 100%;
  width: 100%;
  position: absolute;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    font-size: 24px;
    margin: 30px 0;
  }
}

@media screen and (max-width: 768px) {
  .navigation {
    display: flex !important;
    flex-direction: row !important;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .headerAvatar {
    display: block !important;
  }
}

@media screen and (max-width: 630px) {
  .settingsContainer {
    margin: 1.5rem !important;
  }
}

:global(.ui.header) {
  &.subscriptionSubheader {
    font-weight: bold;
  }
}

.channelName {
  color: $grey;
}

.actionButtons {
  background: transparent;
  padding: 0.25em;

  &:active,
  &:hover {
    background: transparent;
  }

  i {
    font-weight: 900;
    font-size: 14px;
  }
}

.hoursNotificationsModal {
  border-radius: 0px !important;
  max-width: 520px !important;

  :global(.close) {
    top: 0 !important;
    right: 0 !important;
    color: #212121 !important;
    font-size: 18px !important;
    line-height: 21px;
  }

  .modalContent {
    text-align: justify;

    input {
      width: 100%;
      border: none;
    }

    input:focus {
      outline: none;
    }

    hr {
      border: 0;
      border-top: 1px solid #8c8c8c;
      border-bottom: 1px solid #fff;
    }

    .heading {
      color: #212121;
      font-size: 24px;
      line-height: 30px;
      margin-top: 32px;
      margin-bottom: 7px;
      font-weight: bold;
    }

    .content {
      font-size: 14px;
      line-height: 18px;
      color: #212121;
      letter-spacing: -0.0373333px;
    }

    .actionsContainer {
      text-align: right;
      padding-top: 6px;
      padding-bottom: 5px;

      .action {
        font-size: 14px;
        line-height: 21px;
        font-weight: normal;
        margin: 0px;

        &.cancel {
          background-color: $white;
          border: 1px solid #d3d3d3;
          color: #4d4d4d;
        }
      }
    }
  }
}

.colorPickerPopup {
  width: 46px;
  height: 84px;
  padding: 6px 7px;
  position: relative;
  .color {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: black;
    cursor: pointer;
    position: absolute;
    &.colorSelected {
      background-image: url("./components/assets/check-white.svg");
      background-repeat: no-repeat;
      background-position: 3px 4px;
    }
    @for $i from 1 through 8 {
      &.color--#{$i} {
        background-color: nth($palette, $i);
        top: 6px + (floor(($i - 1) / 2) * 20px);
        @if $i % 2 == 0 {
          right: 7px;
        }
      }
    }
  }
}

:global(.rbc-overlay) {
  @for $i from 1 through 8 {
    .calendarEvent--#{$i} {
      color: black;
      font-size: 12px;
      border-color: nth($palette, $i) !important;
      background-color: rgba(nth($palette, $i), 0.2) !important;

      &:hover {
        background-color: rgba(nth($palette, $i), 0.3) !important;
      }
    }
  }
}

.footerButton {
  background: transparent !important;
  padding: 4px !important;
}

.eventViewModal {
  @media (min-width: 420px) {
    width: 523px !important;
  }

  .modalContent {
    padding: 1.5rem;

    .section {
      margin-bottom: 0.5rem;
      margin-left: 14px;
      display: flex;
      align-items: flex-start;

      &.hidden {
        display: none;
      }

      .icon {
        margin: 0 !important;
        color: #685ace;
        position: absolute;
        font-weight: bold;
        left: 22px;
        padding: 0px 4px;
      }

      .eventTitle,
      .eventDetails,
      .eventDescription,
      .eventLink {
        margin: 0 0 0 20px !important;
        width: 100%;
      }

      .eventViewCalendar {
        font-weight: 600;
        font-size: 20px;
        line-height: 17px;
        color: #212121;
        margin: 0 50px 0 20px !important;
        width: 100%;
      }

      .eventTitle {
        font-size: 18px;
        line-height: 18px;
        color: #212121;
      }

      .eventDetails {
        font-size: 16px;
        line-height: 16px;
        color: #4f4f4f;
      }

      .eventDescription {
        font-size: 16px;
        line-height: 16px;
        color: #212121;
      }

      .eventLink {
        font-size: 16px;
        line-height: 16px;
      }

      blockquote {
        margin: 0 0;
      }

      .label {
        margin: 0 !important;
        margin-left: 30px !important;
        width: 90px;
        min-width: 90px;
      }

      .actionButtonGroup {
        position: absolute;
        right: 20px;
      }

      .actionButton {
        background: transparent !important;
        border: none !important;
        padding: 0px 4px !important;
        margin: 0;
        width: auto;

        &:hover,
        &:active,
        &:focus {
          background: transparent !important;
          outline: none;
        }

        i {
          font-weight: bold;
        }
      }
    }

    .guestsContainer {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding-bottom: 0px;

      .eventGuests {
        max-height: 140px;
        overflow-y: visible;
        margin-top: -5px;
        margin-left: 7px;

        .guestName {
          margin-top: 5px;
          margin-left: 6px;
        }
      }
    }

    .viewEventGuests {
      margin-top: -4px;
      padding-left: 20px;
      width: 100%;

      .viewEventGuestsHeader {
        font-size: 20px;
        margin-bottom: 10px;
      }
    }

    .formActions {
      text-align: right;
      margin-top: 14px;

      button {
        margin-bottom: 14px;
      }

      p {
        font-size: 20px;
        line-height: 40px;
        font-weight: 400px;
        position: absolute;
        left: 25px;
      }
    }
  }
}

.headerDate {
  font-size: 16px;
  font-weight: bold;
}

.findTime {
  margin-left: 120px;
  color: #685ace;
  line-height: 1.5;
  font-weight: 600;
  cursor: pointer;

  &:hover {
    color: #33327f;
  }

  &:active {
    outline: none;
  }
}

:global(.ui.dimmer) {
  &.formLoading {
    justify-content: center !important;
  }
}

.schedulerViewModal {
  &:global(.ui.modal.scrolling.fullscreen) {
    width: 100vw !important;
    margin: 0 !important;
    border-radius: 0;
    box-shadow: none;
    & > :global(.content) {
      height: 100%;
      padding: 0;
    }
  }
}

.schedulerHeader {
  .schedulerHeaderTitle {
    color: #685ace;
    font-weight: 700;
    text-align: center;
    line-height: 50px;
    border-bottom: 1px solid #e0e0e0;
  }

  .schedulerHeaderClose {
    position: absolute;
    top: 3px;
    right: 0px;
    font-size: 16px;
    line-height: 1.25;
    box-shadow: none !important;
    border-color: transparent !important;

    &:focus {
      outline: none;
    }

    &:hover {
      box-shadow: none !important;
    }
  }
}

.schedulerContainer {
  height: 100%;
  display: flex;

  .timeline {
    flex: 1;
  }
}

.meetingForm {
  height: 90%;
  overflow-x: hidden;
  overflow-y: auto;
  padding-top: 20px;
  border-right: 1px solid #c4c4c4;
  ::-webkit-scrollbar {
    display: none;
  }

  ~ .timeline {
    max-width: calc(100% - 552px);
  }

  &.collapsed {
    ~ .timeline {
      max-width: calc(100% - 47px);
    }

    .meetingFormFields {
      width: 50px !important;

      .toggleButton {
        left: 12px;
      }
    }

    .section {
      height: 32px;

      .icon {
        &:not(.unclickable) {
          cursor: pointer;

          &:hover {
            color: #424242 !important;
          }
        }
      }
    }
  }

  .meetingFormFields {
    width: 531px !important;
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
    padding-left: 20px;
    padding-right: 20px;
    height: 100%;

    ::-webkit-scrollbar {
      display: none;
    }

    .formField {
      margin-bottom: 0.5rem;
      display: flex;
      align-items: center;
    }
  }

  .section {
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center;

    &.hidden {
      display: none;
    }

    &:nth-child(10) {
      margin-bottom: 64px;
    }

    .icon {
      margin: 0 !important;
      color: #bdbdbd;
      position: absolute;
      font-weight: bold;
      left: 15.5px;
    }

    .label {
      margin: 0 !important;
      margin-left: 30px !important;
      width: 90px;
      min-width: 90px;

      .teamChannelInfoIcon {
        margin-left: 6px !important;
        font-size: 12px !important;
      }

      &.addGuests {
        align-self: flex-start;
      }

      &.hasGuests {
        margin-top: 12px !important;
      }
    }

    .recurrenceFields {
      display: flex;
      align-items: center;
      width: 100%;

      .allDay {
        margin-bottom: 0px;
        width: 120px;
        margin-right: auto;
      }

      .recurrenceInput {
        margin-left: auto;
      }
    }

    .input {
      margin-bottom: 0 !important;
      width: 100%;

      &:focus-within ~ .icon {
        color: #424242 !important;
      }
    }

    .checkbox {
      margin: 4px 0px;

      &:focus-within ~ .icon {
        color: #424242 !important;
      }
    }

    .radioBtn {
      margin-bottom: 0px !important;
      &:focus-within ~ .icon {
        color: #424242 !important;
      }
    }

    .timezoneDropdown {
      margin-top: 5px;
    }

    &.singleDatePicker {
      :global(.SingleDatePicker) {
        vertical-align: middle;
        width: 100%;

        &:focus-within ~ .icon {
          color: #424242 !important;
        }

        :global(.SingleDatePickerInput) {
          width: 100%;

          :global(.DateInput) {
            width: 100%;

            input {
              font-weight: $fontWeightNormal;
            }
          }
        }

        :global(.SingleDatePicker_picker) {
          z-index: 10;

          :global(.CalendarDay__default) {
            border-color: $borderColor;
          }

          :global(.CalendarDay__selected) {
            background-color: $primaryColor;
            border-color: $borderColor;
            color: $white;

            &:active,
            &:hover {
              background-color: $primaryColor;
            }
          }
        }
      }
    }
  }

  .guestsContainer {
    display: flex;
    flex-direction: column;
    width: 100%;

    .addGuestCheckbox {
      margin: 4px 0px;
    }

    .eventGuests {
      max-height: 70px;
      overflow-y: auto;
      margin-top: 8px;

      .guestAttendance {
        font-weight: 900;
        line-height: 1.1;
        margin-right: 10px;

        &.optional {
          font-weight: normal;
        }

        &.disabled {
          opacity: 0.5;
        }
      }

      .guestCheckbox {
        margin: 4px 10px 0px 0px;
      }

      .guestName {
        margin-left: 6px;
      }

      .guestOptionalText {
        font-weight: lighter;
        margin-left: 4px;
      }
    }
  }

  .iconContainer {
    display: flex;
    flex-direction: column;
    align-items: center;

    .iconItem {
      margin-right: 0 !important;
      margin-bottom: 24px;
      color: #bdbdbd;
      font-weight: bold;
    }
  }

  .btnContainer {
    background-color: $white;
    position: sticky;
    height: 64px;
    left: 0;
    bottom: 0px;
    padding: 4px 0 20px 0;
    width: 100%;
    z-index: 8;
    display: flex;
    flex-direction: row-reverse;

    .addToCalendarButton {
      margin-right: 0;
    }

    .toggleButton {
      margin-right: auto;
      box-shadow: none;
      position: relative;
      left: -10px;
      padding-left: 26px;

      .toggleIcon {
        color: #4f4f4f;
        margin-right: 0 !important;
        position: absolute;
        top: 5px;
        left: 0px;
      }

      &:hover {
        box-shadow: none;
      }

      &:focus {
        box-shadow: none;
        outline: none;
      }
    }
  }
}

.meetingFormTimezones {
  margin-top: 0;
  overflow-y: auto;
  padding: 10px 0;
  height: 100%;

  .timezoneToggle {
    color: $blue;
    cursor: pointer;
    font-size: 14px;
    margin: 0 0 0 15px;
  }

  .timezonesContainer {
    margin-top: 10px;
  }
}
