@import "../../theme/mixins";
@import "../../theme/variables";

.closeButton {
  background: none !important;
  font-size: 16px !important;
  margin: 0 !important;
  padding: 0 !important;
}

.content {
  min-height: 260px;
  max-height: 260px !important;
  padding: 0 !important;
}

:global(.ui.modal) > .header {
  align-items: center;
  border-bottom: 1px solid $lightgrey;
  color: $black;
  display: flex;
  padding: 10px 15px;
}

.headerTitle {
  flex: 1;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.5;
  margin: 0 10px 0 0;
  word-break: break-word;
}

:global(.ui.modal).modal {
  @include modal(435px);
}

.user {
  align-items: center;
  display: flex;
  padding: 5px 15px;
}

.userName {
  flex: 1;
  font-size: 15px;
  line-height: 1.2;
  margin-left: 8px;
}

.userSearch {
  font-size: 15px !important;
  margin: 5px 0;
  padding: 5px 15px;
  width: 100%;

  i {
    font-weight: $fontWeightNormal;
    margin-left: 18px !important;
  }

  input {
    margin-left: 2px !important;
  }
}
