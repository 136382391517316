.passwordMeter {
  margin-top: 0 !important;
  margin-bottom: 0.5em !important;

  & > div:first-child {
    min-width: 3em !important;
  }

  & > div:last-child {
    margin-top: 0.125em !important;
    top: 0 !important;
    margin-left: 0.625em !important;
    text-align: left !important;
    line-height: 1 !important;
    font-size: 0.8em !important;
    color: #fff !important;
  }
}
