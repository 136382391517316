@import "../../theme/variables.scss";
@import "../../theme/mixins.scss";

.container {
  margin-bottom: 10px;
  overflow: hidden;
}

.fluid textarea {
  width: 100%;
}

.textarea {
  border-color: $lightgrey;
  border-radius: 4px;
  color: $grey;
  line-height: 1;
  margin-bottom: 10px;
  outline: none;
  padding: 10px 40px 10px 10px;

  &:focus {
    border-color: $black;
    color: $black;
  }
}

.textareaButtons {
  bottom: 0;
  position: absolute;
  right: 0;

  > button {
    background: none !important;
    margin: 0 !important;
    padding: 10px !important;

    i {
      font-weight: 900 !important;
    }
  }
}

.textareaContainer {
  position: relative;
}

.preview {
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 4px;
  min-height: 46px;
  padding: 0.75em 0.9375em;

  img {
    max-width: 100%;
  }
}

.error {
  background: #fff6f6 !important;
  border-color: #e0b4b4 !important;
  color: #9f3a38 !important;
}
