@import "../../theme/variables";

.dateTimePicker {
  width: 100%;

  :global(.SingleDatePicker) {
    vertical-align: middle;
    width: 60%;

    :global(.SingleDatePickerInput) {
      width: 100%;

      :global(.DateInput) {
        width: 100%;

        input {
          font-weight: $fontWeightNormal;
        }
      }
    }

    :global(.SingleDatePicker_picker) {
      z-index: 10;

      :global(.CalendarDay__default) {
        border-color: $borderColor;
      }

      :global(.CalendarDay__selected) {
        background-color: $primaryColor;
        border-color: $borderColor;
        color: $white;

        &:active,
        &:hover {
          background-color: $primaryColor;
        }
      }
    }
  }

  &.reversed {
    :global(.SingleDatePicker) {
      :global(.SingleDatePickerInput) {
        :global(.DateInput) {
          input {
            border-bottom-left-radius: 0 !important;
            border-top-left-radius: 0 !important;
          }
        }
      }
    }

    .timeField {
      :global(.ui.dropdown.selection) {
        border-bottom-right-radius: 0 !important;
        border-top-right-radius: 0 !important;
      }
    }
  }

  &:not(.reversed) {
    :global(.SingleDatePicker) {
      :global(.SingleDatePickerInput) {
        :global(.DateInput) {
          input {
            border-bottom-right-radius: 0 !important;
            border-top-right-radius: 0 !important;
          }
        }
      }
    }

    .timeField {
      :global(.ui.dropdown.selection) {
        border-bottom-left-radius: 0 !important;
        border-top-left-radius: 0 !important;
      }
    }
  }

  &.hideTime {
    :global(.SingleDatePicker) {
      width: auto;
    }
  }

  &.small {
    :global(.SingleDatePicker) {
      width: auto;

      :global(.SingleDatePickerInput) {
        :global(.DateInput) {
          width: 105px;

          input {
            padding: 0.783em 0.5em;
          }
        }
      }
    }

    .timeField {
      :global(.ui.dropdown.selection) {
        width: 75px;
      }
    }
  }
}
