@import "../../theme/variables";

.topbar {
  background-color: $darkpurple;
  display: flex;
  align-items: center;
  padding: 3px 16px;
  width: 100%;

  .logo {
    line-height: 1em;

    img {
      height: 32px;
      cursor: pointer;
    }
  }

  .menu {
    margin: 0px !important;
    margin-left: 40px !important;
    margin-right: auto !important;
    display: flex;
    width: 300px;
    align-items: center;
    min-height: 0px;

    .menuItem {
      display: flex !important;
      flex-direction: row !important;
      align-items: center !important;
      cursor: pointer;
      padding: 0px !important;
      opacity: 0.4;
      margin-right: 30px !important;

      &:last-of-type {
        margin-right: 0px !important;
      }

      &.menuItemActive {
        opacity: 1;
      }

      img {
        height: 14px !important;
        width: 14px !important;
        margin-right: 2px !important;
      }

      :global(.icon) {
        font-size: 14px !important;
        margin: 0px !important;
        color: $white;
        font-weight: 600;
      }

      .menuLabel {
        color: $white;
        font-size: 14px;
        margin-left: 5px;
      }

      .unreadMessages {
        background-color: $orange;
        border-radius: 11px;
        border: solid 1.5px $darkpurple;
        height: 11px;
        position: absolute;
        left: 7px;
        bottom: 6px;
        width: 11px;
      }
    }
  }
}
