.google {
  background-color: #4285f4 !important;
  color: #ffffff !important;
  display: flex !important;
  align-items: center;
  padding: 0px !important;
  font-family: "Roboto", "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans-serif !important;

  img {
    margin-right: 10px;
  }
}
