@import "../../theme/variables";

.topbarDropdown {
  align-items: center;
  color: rgba($white, 0.6) !important;
  display: flex !important;
  max-height: 24.4px;

  &.toHideInBigScreens {
    @media (min-width: $computerBreakpoint) {
      display: none !important;
    }
  }

  :global(.text) {
    margin-right: 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  :global(.dropdown.icon) {
    display: none;
  }

  .topbarDropdownMenu {
    font-size: 15px !important;
    min-width: 200px !important;
    padding: 15px 0 !important;
    width: 200px;
    right: 0;
    left: auto !important;
  }

  .topbarDropdownMenuHeader {
    align-items: center;
    display: flex;
    margin: 0 15px 7px;
  }

  .topbarDropdownMenuHeaderText {
    color: $black;
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
    justify-content: space-between;
    margin: 0 8px;
  }

  .topbarDropdownMenuHeaderTextSubtitle {
    font-size: 13px;
    line-height: 1.38;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .topbarDropdownMenuHeaderTextTitle {
    font-size: 15px;
    font-weight: $fontWeightBold;
    line-height: 1.2;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .topbarDropdownMenuItem {
    background: transparent !important;
    color: $black !important;
    display: block !important;
    font-size: inherit !important;
    line-height: 1.2 !important;
    margin: 0 !important;
    outline: none;
    padding: 5px 15px !important;
    text-align: left !important;
    width: 100%;

    &:hover {
      background: rgba($black, 0.05) !important;
      color: rgba($black, 0.95) !important;
    }
  }
}
