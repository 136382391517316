.checkboxContainer {
  position: relative;
  margin-bottom: 10px;
  line-height: 20px;
  .checkboxInput {
    .originalCheckbox {
      display: none;
      &:indeterminate ~ .newCheckbox {
        background-image: url("./assets/dash.svg");
        background-position: 3px 8px;
      }
      &:checked ~ .newCheckbox {
        background-image: url("./assets/check-black.svg");
        background-position: 1px 3px;
      }
    }
    .newCheckbox {
      cursor: pointer;
      position: absolute;
      display: block;
      top: 1px;
      height: 18px;
      width: 18px;
      border-radius: 2px;
      border: 1px solid #bdbdbd;
      background-repeat: no-repeat;
      &.tall {
        top: 3px;
      }
    }
  }
  .text {
    display: inline-block;
    width: 100%;
    padding-left: 30px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
