@import "../../theme/mixins";
@import "../../theme/variables";

.customModal {
  .customModalHeader,
  .customModalContent {
    padding: 1rem 1.25rem !important;
  }

  i {
    &:global(.close) {
      &:global(.close) {
        top: 16px;
        right: 16px;
        color: #828282;
      }
    }
  }

  .customModalHeader {
    border-bottom: 1px solid #c4c4c4 !important;
  }

  .customModalSection {
    padding-bottom: 1rem;
  }

  .customModalRepeats {
    > div {
      display: inline-block;
      margin-left: 44px;
    }

    .customModalRepeatsLabel {
      margin-left: 0;
      margin-right: 10px;
    }

    .repeatsCheckbox {
      :global(.checkbox) {
        top: 3px;
      }
    }
  }

  .customModalInterval {
    > div {
      display: inline-block;
      margin-left: 10px;

      :global(input) {
        max-width: 75px;
      }

      :global(.ui.selection.dropdown) {
        line-height: unset;
        min-width: 0;
      }
    }

    .customModalIntervalLabel {
      margin-left: 0;
      margin-right: 10px;
    }
  }

  .customModalWeekday {
    .dayBtn {
      color: $white !important;
      font-weight: normal !important;
      height: 50px;
      margin-right: 10px;
      margin-top: 5px;
      width: 50px;

      &.active {
        background-color: $primaryColor !important;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .customModalEnd {
    > div:not(last-child) {
      margin-bottom: 5px;
    }
    .radioFloat {
      display: inline-block;

      &:first-child {
        margin-right: 10px;
        min-width: 80px;
      }
      &:last-child {
        width: 200px;

        :global(.ui.labeled.input) {
          width: 80px;
        }
      }
    }
  }
}
