@import "../../theme/variables";

.autocompleteContainer {
  background-color: $white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14);
  color: $darkgrey;
  display: block;
  font-size: 13px;
  line-height: 1.2;
  margin-top: 6px;
  padding: 10px 0;
  position: absolute;
  width: 100%;
  z-index: $zIndexAutocomplete;
}

.fuzzySearch {
  font-size: 14px !important;
  line-height: 1.2;
  width: 100%;

  input {
    padding: 11px 10px !important;
  }
}

.fuzzySearchContainer {
  position: relative;
}

.searchMatch {
  cursor: pointer;
  margin: 2px 0;
  overflow: hidden;
  padding: 0 10px;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:first-of-type {
    margin-top: 0;
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  &:hover,
  &.selected {
    background: rgba($blue, 0.1);
  }
}
