.scrollableContent {
  overflow: hidden;
  position: relative;
  height: 100%;

  .scrollbarTrack {
    position: absolute;
    width: 4px;
    transition: opacity 500ms ease 0s;
    opacity: 0;
    right: 2px;
    bottom: 3px;
    top: 3px;
    border-radius: 3px;

    &.showAlways {
      opacity: 1;
    }

    .scrollbarThumb {
      position: relative;
      display: block;
      width: 100%;
      border-radius: 4px;
      background-color: rgba(0, 0, 0, 0.15);
    }
  }
}

.content {
  overflow-x: hidden;
  overflow-y: scroll;
  height: 100%;

  &::-webkit-scrollbar {
    width: 4px;
    display: none;
  }
}
