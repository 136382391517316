@import "../../theme/mixins";
@import "../../theme/variables";

$sectionWidth: 600px;

.sectionButtons {
  display: flex;
  flex-direction: row-reverse;
}

.sectionButtonsPrimary {
  @include button();
  margin-right: 0 !important;
  padding: 10px 20px !important;
  width: auto !important;
}

.sectionCancel,
.sectionArrow {
  background: none !important;
  color: rgba($black, 0.5) !important;
  padding: 0 !important;

  &.solid i {
    font-weight: 900;
  }

  &:global(.ui.disabled) {
    background: none !important;
  }
}

.sectionArrow {
  color: #212121 !important;
}

.sectionCancel {
  color: $black !important;
}

.sectionColumnAction {
  width: 23px;
}

.sectionInput {
  width: 100%;

  input {
    color: $grey !important;
  }

  &.dirty {
    input {
      border-color: $black !important;
      color: $black !important;
    }
  }
}

.sectionRow {
  max-width: 600px;
}

.budgetYear {
  display: inline-flex;
  border: 1px solid #bdbdbd;
  border-radius: 2px;
  padding: 0px 10px;

  span {
    font-size: 20px;
    font-weight: 400;
    padding: 10px 30px;
  }
}

.sectionBudget {
  display: flex;
  margin: 20px 0;
}

.sectionBudgetRow {
  display: flex;
  justify-content: space-between;
  padding-top: 4px;
  padding-bottom: 4px;
}

.sectionBudgetLabel {
  align-items: center;
  display: flex !important;
  font-size: 15px;
  line-height: 24px;
  margin-left: 10px;
  width: 98px;
}

.sectionBudgetInputContainer {
  display: flex;
  justify-content: space-between;

  .sectionBudgetInput {
    font-size: 14px;
    width: 75%;
  }
}
