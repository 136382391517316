@import "../../theme/variables.scss";

.dropdownMenu {
  display: flex !important;
  align-items: center;
  color: $white;
  font-size: 14px;
  margin-left: 20px;
  margin-right: auto;

  .selectedMenu {
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    img {
      height: 14px;
      width: 14px;
      align-self: center;
      margin-right: 2px;
    }

    :global(.icon) {
      font-size: 14px !important;
      margin-right: 0px;
      font-weight: 600;
    }

    .menuLabel {
      margin-left: 5px;
    }

    .unreadMessages {
      background-color: $orange;
      border-radius: 11px;
      border: solid 1.5px $darkpurple;
      height: 11px;
      position: absolute;
      left: 8px;
      top: 2px;
      width: 11px;
    }
  }

  .menuItem {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;

    img {
      height: 14px !important;
      width: 14px !important;
      margin-left: 1px !important;
      opacity: 0.7;
    }

    :global(.icon) {
      font-size: 14px !important;
      font-weight: 600;
      opacity: 0.7;
    }

    .menuLabel {
      font-size: 14px;
      opacity: 0.7;
    }

    &:hover,
    &:global(.active) {
      img,
      .menuLabel,
      :global(.icon) {
        opacity: 1 !important;
      }
    }

    .unreadMessages {
      background-color: $orange;
      border-radius: 11px;
      border: solid 1.5px $white;
      height: 11px;
      position: absolute;
      left: 27px;
      top: 8px;
      width: 11px;
    }
  }
}
