@import "../../../theme/variables";
@import "../../../theme/mixins";

:global(.ui.basic.modal).modal {
  > :global(.close) {
    top: -2rem;

    @media (max-width: $computerBreakpoint) {
      top: -35px !important;
      right: -10px !important;
    }
  }

  :global(.content) {
    background: $white;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  :global(.actions) {
    background-color: $black;
    padding: 0.5rem;
    white-space: nowrap;
  }
}

.attachmentsWrapper {
  flex: initial !important;
  max-height: 70px;

  :global(.ui.basic.red.label) {
    color: #fff !important;

    &.messageBoxAttachment {
      :global(.detail) {
        color: #fff;
      }
    }

    &:hover,
    &:active {
      background-color: #d01919 !important;
      color: #fff !important;
      border: none !important;
      box-shadow: none !important;
    }
  }

  :global(.ui.basic.label) {
    border-radius: 12.5px;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.14px;
    font-weight: normal;
    border: none;
    margin-bottom: 4px;

    &.messageBoxAttachment {
      border-radius: 12.5px;
      background: #e1f1f9;
      color: #4d4d4d;

      &.loading {
        background: #e8e8e8;
      }

      :global(.detail) {
        color: #7a7a7a;
      }
    }
  }
}

.attachmentContainer {
  display: flex;
  margin: 2px;
  min-height: 48px;
  align-self: flex-end;

  &.imageAttachment {
    display: block;

    .attachment {
      border-bottom-right-radius: 2px;
      border-bottom-left-radius: 2px;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      background-color: $white;

      &.collapsed {
        border-top-left-radius: 2px;
        border-top-right-radius: 2px;
      }
    }
  }

  @media (min-width: $computerBreakpoint) {
    width: 316px;
  }
}

.attachment {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  border: 1px solid #e8e8e8;
  padding: 8px;
  display: flex;
  flex-grow: 1;
  min-height: 48px;
  background-color: $white;
}

.attachmentIcon {
  min-height: 48px;
  min-width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e8e8e8;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;

  i {
    font-weight: normal;
    color: #424242 !important;
    font-size: 15px !important;
  }
}

.attachmentDetail {
  flex-grow: 1;

  p {
    margin: 0;
  }

  .heading {
    font-size: 14px;
    line-height: 18px;
    color: #212121;
    font-weight: normal;
    letter-spacing: -0.0373333px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;

    @media (max-width: $tabletBreakpoint) {
      max-width: 110px;
    }

    a {
      color: #212121 !important;
    }

    i {
      font-size: 12px;
      color: #212121;
      cursor: pointer;
    }
  }

  .subheading {
    font-size: 12px;
    line-height: 15px;
    color: #a6a6a6;
    letter-spacing: 0.14px;
    font-weight: normal;
  }
}

:global(.sidePanel) {
  .attachmentDetail {
    .heading {
      max-width: 150px;

      @media (max-width: $tabletBreakpoint) {
        max-width: 110px;
      }
    }
  }
}

@media (max-width: $tabletBreakpoint) {
  .imageAttachment .heading {
    max-width: 158px;
  }

  :global(.sidePanel) {
    .imageAttachment .attachmentDetail .heading {
      max-width: 158px;
    }
  }
}

.attachmentButton {
  display: flex;
  align-items: center;

  :global(.ui.basic.button) {
    padding: 3px 6px;
    color: #909090 !important;
    background: transparent !important;
    border: 1px solid transparent;
    border-right-width: 0;
    box-shadow: none !important;
    font-size: 12px;
    height: 25px;
    text-align: center;
    vertical-align: baseline;

    &:hover,
    &:active {
      box-shadow: none !important;
      background: transparent !important;
    }

    > :global(.icon) {
      height: 12px;
      vertical-align: baseline;
      font-weight: 900;
      font-size: 12px;
      line-height: 14px;

      padding-bottom: 13px;
      border-bottom: 2px solid #909090;
    }
  }
}

:global(.sidePanel) {
  .attachmentsWrapper {
    max-height: 200px;
  }
}

.collapsed {
  img,
  audio,
  video {
    display: none !important;
  }

  :global(.pg-viewer-wrapper) {
    display: none !important;
  }
}
