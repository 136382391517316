// Breakpoints
$mobileBreakpoint: 320px;
$tabletBreakpoint: 768px;
$computerBreakpoint: 992px;
$largeMonitorBreakpoint: 1200px;
$widescreenMonitorBreakpoint: 1920px;

// Colors
$black: #212121 !default;
$purple: #685ace !default;
$red: #f45f53 !default;
$green: #9dd624 !default;
$white: #ffffff !default;
$darkgrey: #424242 !default;
$offwhite: #f5f5f5 !default;
$lightgrey: #e0e0e0 !default;
$yellow: #f8b71c !default;
$blue: #2096f3 !default;
$lightgreen: #11e51b !default;
$grey: #757575 !default;
$orange: #ee7b46 !default;
$darkpurple: #3c3786 !default;

$primaryColor: $purple !default;
// $primaryColor: $darkgrey !default;

$borderColor: $lightgrey !default;
$dividerColor: $lightgrey !default;
// $borderColor: darken($darkgrey, 20%) !default;
// $dividerColor: darken($darkgrey, 20%) !default;

// delete colors
$deleteColor: #e03b41;

// Fonts
$fontFamilyLogo: "Fira Sans" !default;

$fontColor: $black !default;
$fontColorInverted: $white !default;
$fontWeightLight: 300 !default;
$fontWeightNormal: 400 !default;
$fontWeightMedium: 500 !default;
$fontWeightBold: 600 !default;
$fontWeightHeavy: 700 !default;

// Z-index
$zIndexAutocomplete: 1000;
$zIndexEvents: 1000;
$zIndexNotification: 1000;
$zIndexSortable: 10000;
$zIndexChannelOptions: 1001;

// message list width
$messageContainerWidth: 958px;
