@import "../../theme/variables.scss";
@import "../../theme/mixins.scss";

$sidebarBackgroundColor: $primaryColor;
$sidebarFontColor: #4d4d4d;
$sidebarFontColorHover: #212121;
$sidebarGutter: 18px;

.hamburger {
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  background-color: $darkpurple;
  padding: 3px 16px;
  width: 100%;

  :global(.ui.button) {
    flex: 0 0 auto;
    background: transparent;
    font-size: 16px;
    padding: 0;
    color: $white;
  }

  .barIcon {
    font-size: 20px;
  }

  .logo {
    line-height: 1em;

    img {
      height: 32px;
      cursor: pointer;
    }
  }
}

.messageSidebarSection {
  height: 100%;
}

.sidebar {
  background-color: $offwhite;
  border-right: 1px solid #d0d0d0;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 15px 0;
  width: 220px;
}

.sidebarContent {
  height: 100%;
  position: relative;
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    width: 4px;
    display: none;
  }
}

.sidebarUnread {
  background-color: $orange;
  border-radius: 4px;
  border: 1px solid $orange;
  cursor: pointer;
  color: $white;
  font-size: 12px;
  line-height: 15px;
  font-weight: normal;
  left: 30px;
  padding: 5px 12px;
  position: fixed;
  text-align: center;
  width: 160px;
  z-index: 1;
  letter-spacing: 0.14px;
  text-align: center;

  &.unreadBelow {
    bottom: 10px;
  }
}

.sidebarDropdown {
  align-items: center;
  color: $sidebarFontColor !important;
  display: flex !important;
  font-size: 13px;
  margin: 0 15px 10px;

  :global(.text) {
    margin-right: 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.sidebarDropdownMenu {
  font-size: 15px !important;
  min-width: 200px !important;
  padding: 15px 0 !important;
  width: 200px;
}

.sidebarDropdownMenuHeader {
  align-items: center;
  display: flex;
  margin: 0 15px 7px;
}

.sidebarDropdownMenuHeaderText {
  color: $black;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  justify-content: space-between;
  margin: 0 8px;
}

.sidebarDropdownMenuHeaderTextSubtitle {
  font-size: 13px;
  line-height: 1.38;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sidebarDropdownMenuHeaderTextTitle {
  font-size: 15px;
  font-weight: $fontWeightBold;
  line-height: 1.2;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sidebarDropdownMenuItem {
  background: transparent !important;
  color: $black !important;
  display: block !important;
  font-size: inherit !important;
  line-height: 1.2 !important;
  margin: 0 !important;
  outline: none;
  padding: 5px 15px !important;
  text-align: left !important;
  width: 100%;

  &:hover {
    background: rgba($black, 0.05) !important;
    color: rgba($black, 0.95) !important;
  }
}

.sidebarHeader {
  align-items: center;
  color: $sidebarFontColor;
  display: flex;
  justify-content: space-between;
  margin-left: 15px;

  :global(#tabNotifications) {
    i {
      color: $sidebarFontColor;
    }

    :global(#notifBellUnread) {
      border: solid 1.5px $lightgrey !important;
    }
  }
}

.sidebarHeaderLink {
  color: $sidebarFontColor;

  &:hover {
    color: $sidebarFontColor;
    opacity: 0.8;
  }
}

.sidebarHeaderIcon {
  font-size: 14px !important;
  font-weight: 900 !important;
}

.sidebarHeaderText {
  display: inline-block;
  font-family: $fontFamilyLogo;
  font-size: 18px;
  font-weight: $fontWeightMedium;
  margin: 0 7px 0 0;
}

.sidebarMessageRoom {
  background: none !important;
  color: $sidebarFontColor !important;
  font-size: 14px !important;
  line-height: 1 !important;
  padding: 0 0px !important;
  position: relative;
  text-align: left !important;
  width: 100%;

  i {
    font-size: 13px;
    font-weight: 900 !important;
    margin-right: 7px !important;
  }

  :global(i.circle) {
    color: #c1c1c1;
  }

  :global(i.chevron) {
    font-size: 15px;
  }

  &:global(.ui.disabled) {
    background: none !important;
  }

  &:focus {
    outline: none !important;
  }

  &:hover > .sidebarMessageRoomContent {
    background-color: rgba(#33327f, 0.9);
    color: $white;

    &.closeable > .unreadMessageRoomNotif {
      display: none !important;
    }
  }

  &:focus > .sidebarMessageRoomContent,
  &.active > .sidebarMessageRoomContent {
    background-color: #33327f;
    color: $white;
  }

  &.online i:global(.circle.icon) {
    color: $lightgreen !important;
  }

  &.unread {
    color: $sidebarFontColor !important;
    font-weight: $fontWeightBold;

    i,
    span {
      opacity: 1 !important;
    }

    .unreadMessageRoomNotif {
      display: inline !important;
    }
  }

  :global(.ui.button).closeButton {
    box-shadow: none;
    color: $white !important;
    margin: 0;
    padding: 10px;
    position: absolute;
    right: -6px;
    top: 50%;
    transform: translateY(-50%);

    &:global(.icon) {
      color: $white;
    }

    &:hover {
      background: transparent !important;
      color: $white !important;
    }
  }

  .sidebarMessageRoomContent {
    display: inline-block;
    height: 100%;
    overflow: hidden;
    padding: 6px 20px 6px $sidebarGutter !important;
    text-overflow: ellipsis;
    width: 100%;
    position: relative;

    &.closeable {
      padding-right: 30px;
    }

    i,
    span {
      opacity: 0.7;
    }

    .unreadMessageRoomNotif {
      display: none;
      background-color: $orange;
      border-radius: 6px;
      width: 6px;
      height: 6px;
      position: absolute;
      right: 7px;
      top: 11px;
    }
  }

  :global(.ui.button).closeButton {
    display: none;
  }

  &:hover :global(.ui.button).closeButton,
  &:focus :global(.ui.button).closeButton,
  &:active :global(.ui.button).closeButton {
    display: block;
  }
}

.sidebarProjectCollapsed {
  background-color: rgba(104, 90, 206, 0.1);
  border-left: 2px solid #685ace;

  .unreadProjectNotif {
    display: none !important;
  }
}

.sidebarProject {
  background: none !important;
  color: $sidebarFontColor !important;
  font-size: 14px !important;
  line-height: 1 !important;
  margin: 0 !important;
  padding: 0 !important;
  text-align: left !important;
  width: 100%;
  position: relative;

  i {
    font-weight: 900 !important;
  }

  &:global(.ui.disabled) {
    background: none !important;
  }

  &:focus {
    outline: none !important;
  }

  &.unread {
    color: $sidebarFontColor !important;
    font-weight: $fontWeightBold;

    i,
    span {
      opacity: 1;
    }

    .unreadProjectNotif {
      display: inline;
    }
  }

  &:hover > .sidebarProjectContent,
  &:focus > .sidebarProjectContent {
    background-color: rgba(#33327f, 0.9);
    color: $white;

    &.closeable > .unreadProjectNotif {
      display: none !important;
    }
  }

  :global(.ui.button).settingsButton {
    box-shadow: none;
    color: $white !important;
    margin: 0;
    padding: 10px;
    position: absolute;
    right: -6px;
    top: 50%;
    transform: translateY(-50%);
    background: transparent;
    display: none;

    &:global(.icon) {
      color: $white;
      font-size: 12px;
      transition: none;

      i {
        transition: none;
      }
    }

    &.active {
      display: block;
      color: $sidebarFontColor !important;
    }

    &:hover {
      background: transparent !important;
      color: $white !important;
    }
  }

  &:hover :global(.ui.button).settingsButton,
  &:focus :global(.ui.button).settingsButton,
  &:active :global(.ui.button).settingsButton {
    display: block;
    color: $white !important;
  }
}

.sidebarProjectContent {
  display: inline-block;
  line-height: 25px;
  overflow: hidden;
  padding: 1px 20px 1px $sidebarGutter !important;
  text-overflow: ellipsis;
  width: 100%;
  position: relative;

  i {
    margin-right: 7px;
    opacity: 0.7;
  }

  span {
    opacity: 0.7;
  }

  .unreadProjectNotif {
    display: none;
    background-color: $orange;
    border-radius: 6px;
    width: 6px;
    height: 6px;
    position: absolute;
    right: 7px;
    top: 11px;
  }
}

.sidebarProjectRoomList {
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
}

.sidebarProjectRoom {
  background: none !important;
  color: $sidebarFontColor !important;
  font-size: 13px !important;
  margin: 0 !important;
  padding: 0 !important;
  text-align: left !important;
  width: 100%;
  position: relative;

  i {
    font-size: 12px !important;
    font-weight: 900 !important;
  }

  &:global(.ui.disabled) {
    background: none !important;
  }

  &:focus {
    outline: none !important;
  }

  &:hover > .sidebarProjectRoomText {
    background-color: rgba(#33327f, 0.9);
    color: $white;
  }

  &:focus > .sidebarProjectRoomText,
  &.active > .sidebarProjectRoomText {
    background-color: #33327f;
    color: $white;
  }

  &.unread {
    color: $sidebarFontColor !important;
    font-weight: $fontWeightBold;

    i,
    span {
      opacity: 1;
    }

    .unreadProjectRoomNotif {
      display: inline;
    }
  }

  .unreadProjectRoomNotif {
    display: none;
    background-color: $orange;
    border-radius: 6px;
    width: 6px;
    height: 6px;
    position: absolute;
    right: 7px;
    top: 11px;
  }
}

.sidebarProjectRoomText {
  display: inline-block;
  height: 100%;
  overflow: hidden;
  padding: 8px 15px 8px 38px;
  text-overflow: ellipsis;
  width: 100%;

  i {
    margin-right: 7px;
    opacity: 0.7;
  }

  span {
    opacity: 0.7;
  }
}

.sidebarSearch {
  margin: 0 17px 22px;
  position: relative;
  border: 1px solid #d3d3d3;
  border-radius: 2px;

  .sidebarSearchIcon {
    bottom: 0;
    color: $sidebarFontColor;
    display: block;
    font-size: 14px;
    font-weight: $fontWeightBold;
    left: 15px;
    opacity: 0.7;
    position: absolute;
    text-align: left;
    top: 5px;
    width: 32px;
  }

  input[type="search"] {
    background-color: $white;
    border: 0;
    box-sizing: border-box;
    color: $sidebarFontColor;
    cursor: pointer;
    display: block;
    font-family: "Source Sans Pro", Arial, Helvetica, sans-serif;
    font-size: 14px;
    opacity: 0.8;
    outline: none;
    padding: 10px 10px 10px 37px;
    transition: all ease-in 0.1s;
    width: 100%;

    @include placeholder {
      color: $sidebarFontColor;
    }

    &:active,
    &:focus {
      cursor: text;
      opacity: 1;
      transition: all ease-in 0.1s;
    }
  }
}

.sidebarSection {
  margin-bottom: 15px;

  &:last-of-type {
    margin-bottom: 75px;
  }

  &.unread {
    color: $sidebarFontColor !important;
    font-weight: $fontWeightBold;

    i,
    span {
      opacity: 1;
    }

    .unreadProjectListNotif {
      display: inline;
    }
  }
}

.sidebarSectionHeader {
  align-items: center;
  cursor: default;
  display: flex;
  justify-content: space-between;
  margin-bottom: 13px;

  &[tabindex] {
    cursor: pointer;
  }

  &:hover {
    .sidebarSectionHeaderContent {
      .unreadProjectListNotif {
        display: none;
      }
    }

    .sidebarSectionHeaderText {
      color: $sidebarFontColorHover;

      &.collapsible > .unreadProjectNotif {
        display: none !important;
      }
    }

    .sidebarSectionHeaderButton {
      display: inline-block;
      color: $sidebarFontColorHover;

      & > i {
        color: $sidebarFontColorHover;
      }
    }
  }
}

.sidebarSectionHeaderContent {
  display: inline-block;
  line-height: 25px;
  overflow: hidden;
  padding: 0px !important;
  text-overflow: ellipsis;
  width: 100%;
  position: relative;

  .unreadProjectListNotif {
    display: none;
    background-color: $orange;
    border-radius: 6px;
    width: 6px;
    height: 6px;
    position: absolute;
    right: 7px;
    top: 6px;
  }
}

.sidebarSectionHeaderButton {
  background: none;
  border: none;
  box-shadow: none;
  cursor: pointer;
  margin: 0 10px 0 0;
  padding: 0;
  line-height: 16px;

  & > i {
    color: $sidebarFontColor;
    font-size: 14px;
    font-weight: 900;
    margin: 0;
    opacity: 0.95;
  }

  @media (min-width: $computerBreakpoint) {
    display: none;
  }
}

.sidebarSectionHeaderText {
  color: $sidebarFontColor;
  font-size: 13px;
  text-transform: uppercase;
  line-height: 16px;
  margin-bottom: 0;
  margin-left: $sidebarGutter;
  margin-top: 0;

  &.collapsible {
    cursor: pointer;
  }

  :global(.icon) {
    font-weight: $fontWeightBold;
    font-size: 14px;
    margin-right: 9px;
  }
}
