.inlinePopup {
  margin-top: 8px !important;

  @media (max-width: 991px) {
    left: 0 !important;
    right: 0 !important;
    max-width: none !important;
    width: 100% !important;
    max-height: 400px;
    overflow-y: auto;
    padding-bottom: 20px !important;
  }

  &:global(.wide) {
    width: 350px;
    &:global(.very) {
      width: 550px;
    }
  }
}
