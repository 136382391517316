@font-face {
  font-family: "Fira Sans";
  src: url("theme/fonts/FiraSans-Medium.eot");
  src: url("theme/fonts/FiraSans-Medium.eot?#iefix") format("embedded-opentype"),
    url("theme/fonts/FiraSans-Medium.woff2") format("woff2"),
    url("theme/fonts/FiraSans-Medium.woff") format("woff"),
    url("theme/fonts/FiraSans-Medium.ttf") format("truetype"),
    url("theme/fonts/FiraSans-Medium.svg#FiraSans-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: local("Roboto-Medium"), url(./theme/Roboto-Medium.ttf) format("truetype");
}

body {
  background-color: white;
  margin: 0;
  padding: 0;
}

blockquote {
  border-left: 3px solid gray;
  margin-left: 0;
  margin-right: 0;
  padding-left: 15px;
}

#root {
  height: 100%;
}

#root > .shortcuts {
  height: 100%;
  display: flex;
  flex-direction: column;
}

#root > .shortcuts > .pushable {
  height: 100%;
  display: flex;
  flex-direction: column;
}

#root > .shortcuts > .pushable > .pusher.global {
  display: flex;
  flex-direction: column;
  min-height: auto;
  flex: 1;
  overflow: auto;
}

#root > .shortcuts > .pushable > .pusher.global.loggedin {
  flex-direction: column;
}

@media (min-width: 991px) {
  #root > .shortcuts > .pushable > .pusher.global.loggedin {
    flex-direction: row;
  }
}

#root > .shortcuts > .pushable > .sidebar {
  width: auto;
}

.loadedContent,
.loadedContent .pushable,
.loadedContent .pushable > div > div {
  flex: 1;
  overflow: auto;
  /* min-height: 100vh; */
  /* max-height: 100vh; */
}

.loadedContent > * {
  flex: 1;
}

/* .loadedContent .feedback {
  overflow: hidden;
} */

.ui.borderless.menu {
  border: none;
}

.ui.menu.menu {
  /*border-radius: 0;*/
}

.ui.segment.segment {
  border-radius: 0;
}

.dimmer.visible.transition {
  display: flex !important;
}

.footer {
  margin: 0;
  padding: 15px;
  background-color: rgba(245, 246, 248, 0.7);
  border-top: 1px solid #808080;

  font-weight: bold;
  text-align: center;
}

.footer a:link,
.footer a:visited {
  color: #808080;
}

.footer a:active,
.footer a:hover {
  color: #000000;
}

.fullscreen {
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 0;
  overflow: hidden;
}

.react-cookie-banner {
  position: fixed;
  bottom: 0;
  text-align: center;
  height: auto;
  background-color: rgb(72, 72, 72);
  width: 100%;
  z-index: 10000;
  padding: 15px;
}

.react-cookie-banner .cookie-message {
  display: block;
  line-height: 1.5;
  font-weight: 500;
  color: rgb(240, 240, 240);
  margin-right: 100px;
}

.react-cookie-banner .cookie-link {
  padding: 0 5px;
}

.react-cookie-banner .button-close {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 3px;
  border: 0;
  background-color: #757575;
  font-family: "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans-serif;
  padding: 0.875em 1.5em 0.875em;
  cursor: pointer;
  color: #ffffff;
}

.react-cookie-banner .button-close:hover {
  background-color: #828282;
}

.pg-viewer-wrapper {
  max-height: 480px;
  background: white;
}

.pg-viewer-wrapper .video-container {
  justify-content: left !important;
  max-width: 320px;
}

.pg-viewer-wrapper .video-container video {
  max-width: 320px;
}

.pg-viewer-wrapper div.unsupported-message {
  display: none;
}

i.icon.bookmark,
i.icon.circle {
  font-weight: 900;
}

@font-face {
  font-family: "Source Sans Pro";
  src: local("SourceSansPro-Regular"), url(./theme/SourceSansPro-Regular.ttf) format("truetype");
}

input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: searchfield-cancel-button;
}

.shortcuts {
  display: block;
}

.shortcuts:focus {
  outline: 0;
}

.emoji-mart {
  top: 0;
  position: fixed;
  z-index: 999;
}

.emoji-mart-scroll {
  max-height: calc(60vh - 47px - 31px - 12px);
}

.ui.container img {
  max-width: 100%;
}

.rbc-time-slot {
  font-size: 12px;
  text-align: center;
}

.ui.dropdown > .dropdown.icon.clear:before {
  font-family: Icons;
  content: "\f00d" !important;
}

.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px; /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: "liga";
}
