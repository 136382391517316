@import "../../../theme/mixins";
@import "../../../theme/variables";

.closeButton {
  background: none !important;
  font-size: 16px !important;
  margin: 0 !important;
  padding: 0 !important;
}

.content {
  padding: 15px 20px !important;
}

.customInput {
  align-items: center;
  display: flex;

  :global(.SingleDatePicker) {
    flex: 1;
    margin-right: 9px;
  }

  :global(.SingleDatePickerInput) {
    border: none !important;
    width: 100%;
  }

  :global(.DateInput) {
    width: 100%;
  }

  :global(.DateInput_input) {
    border: 1px solid $lightgrey;
    border-radius: 4px;
    color: $grey;
    font-family: inherit;
    font-size: 14px;
    font-weight: 400;
    height: 40px;
    padding: 0 10px;

    &:hover {
      border-color: rgba(34, 36, 38, 0.35);
    }
  }
}

.customInputTime {
  flex: 1;
  font-size: 14px;
  line-height: 1;
  height: 40px;

  :global(.dropdown) {
    color: $grey !important;
    min-height: auto !important;
    min-width: auto !important;
    height: 40px;
    padding: 0 10px !important;
    width: 100%;
  }

  :global(.dropdown) > input {
    color: $grey;
    font-family: inherit;
    padding: 0 10px !important;
  }

  :global(.dropdown) > :global(.text) {
    line-height: 1 !important;
    top: 50%;
    transform: translateY(-50%);
  }

  :global(.ui.dropdown:not(.button)) > :global(.default.text) {
    color: inherit;
  }
}

:global(.ui.modal).modal {
  @include modal(390px);
}

:global(.ui.modal) > .header {
  align-items: center;
  border-bottom: 1px solid $lightgrey;
  color: $black;
  display: flex;
  padding: 10px 15px;
}

.headerTitle {
  flex: 1;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.5;
  margin: 0 10px 0 0;
  word-break: break-word;
}

.setButton {
  background-color: $primaryColor !important;
  color: $white !important;
  font-size: 14px !important;
  margin-top: 10px !important;
}
