@import "../../theme/variables.scss";

.list {
  display: flex;
}

.listContainer{
  flex: 1;
}

.memberList {
  flex: 1;
}

.header{
  display: flex;
  flex-direction: row;
  font-size: 16;
}

.sectionColumn {
  flex: 1;
  margin: 0 3px;

  @media (max-width: 480px) {
    margin-top: 10px;
  }

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
}

.sectionNameColumn {
  flex: 1;
  margin: 0 3px;

  @media (max-width: 991px) {
    min-width: 45%;
  }

  @media (max-width: 480px) {
    min-width: 55%;
  }

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
}

.sectionColumnInput {
  flex: 1;
  margin: 0 8px 0 3px;
  max-width: 60px;

  @media (max-width: 991px) {
    max-width: 120px;
  }

  @media (max-width: 480px) {
    max-width: 120px;
    margin-top: 10px;
    margin-left: 25px;
  }

  @media (max-width: $mobileBreakpoint) {
    max-width: 80px;
    margin-top: 10px;
    margin-left: 25px;
  }

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
}

.sectionColumnAction {
  width: 24px;
}

.sectionColumnAvatar{
  width: 32px;
  padding-top: 4px;
  padding-left: 2px;
}

.sectionRowHeader {
  display: flex;
  margin: 4px 0;

  @media (max-width: 480px) {
    display: none;
  }
}

.memberContainer {
  display: flex;
  flex-direction: row;
  padding-bottom: 20px;

  @media (max-width: 480px) {
    flex-wrap: wrap;
  }
}

.removeIcon {
  color: #828282;
  cursor: pointer;
  padding-top: 10px;
}

.member {
  display: flex;
  align-items: center;
}

.action {
  display: flex;
  align-items: center;
}

.memberName {
  font-size: 14px;
  margin-left: 11px;
  max-width: 110px;
  padding-top: 10px;
  word-wrap: break-word;

  &.isbldaiParentNode {
    padding-top: 0 !important;
  }

  @media (max-width: 991px) {
    max-width: 100%;
  }

  a {
    text-decoration: none;
    color: #212121;
  }

  .isbldai {
    font-size: 12px;
    color: #828282;
  }
}

.actionContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.spacer {
  width: 24px;
}

.edit {
  width: 16px;
  height: 16px;
  margin-top: 10px;
  background-image: url("./assets/edit.svg");
  cursor: pointer;
}

.sectionMemberRate {
  min-height: 40px !important;
  width: 60px !important;
  font-size: 14px;

  @media (max-width: 991px) {
    width: 100%  !important;
  }
}

.sectionMemberType {
  color: $grey;
  min-height: 40px !important;
  min-width: 125px !important;
  font-size: 14px;
}

.actionButtons {
  background: transparent;
  padding: 0.25em;

  &:active,
  &:hover {
    background: transparent;
  }

  i {
    font-weight: 900;
    font-size: 14px;
  }
}

.editMemberModal {
  transform: translate(-50%,-50%);
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 0px !important;
  padding: 1rem !important;
  width: 402px !important;

  .label {
    margin: 0.5rem 0 !important;
  }

  .input {
    margin-bottom: 0 !important;
  }

  .formActions {
    text-align: right;
    margin: 1rem 0 0 !important;
  }
}

:global(.ui.modal) > .modalContent {
  padding: 0;
}
