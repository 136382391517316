.main {
  width: 100%;
  overflow-y: auto;
  flex: 1 1;
  padding-bottom: 5rem;
}

.headerContainer {
  text-align: center;
}

:global(.ui.header) {
  &.header {
    // font-weight: normal;
    // line-height: 55px;
    margin: {
      top: 5rem;
      bottom: 0;
    }
  }

  &.subHeader {
    // font-weight: normal;
    // line-height: 26px;
    margin: {
      top: 0.5rem;
      bottom: 3rem;
    }
  }

  &.planName {
    margin: {
      bottom: 1.5rem;
    }
  }
}

:global(.ui.message) {
  &.errorMessage {
    margin: {
      bottom: 2rem;
    }
  }
}

:global(.ui.segment) {
  &.secureSegment {
    border-radius: 5px;
    padding-bottom: 2rem;
  }

  &.planSegment {
    border-radius: 5px;
  }
}

.amountUnits {
  font-size: 1rem;
  color: #757575;
}

.amountValue {
  font-size: 3rem;
}

.amountInterval {
  font-size: 1.25rem;
  color: #757575;
}

.planTrial {
  margin: {
    top: 0.5rem;
  }
}

.trialText {
  color: #757575;
  font-weight: bold;
}

.textInput {
  &:global(.ui.input) {
    margin: {
      bottom: 1rem;
    }

    > input::placeholder {
      color: #777;
    }
  }
}

.cardElement {
  margin: {
    bottom: 1rem;
  }
  max-width: 100%;
  flex: 1 0 auto;
  outline: 0;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  text-align: left;
  line-height: 1;
  font-family: "Source Sans Pro", Arial, Helvetica, sans-serif;
  padding: 0.75em 0.9375em;
  background: #fff;
  border: 1px solid #e0e0e0;
  color: #212121;
  border-radius: 4px;
  transition: box-shadow 0.1s ease, border-color 0.1s ease;
  box-shadow: none;

  &:global(.StripeElement--focus) {
    border-color: #212121;
    background: #fff;
    color: rgba(0, 0, 0, 0.8);
    box-shadow: none;
  }
}
