@import "variables";

@mixin button($background: $primaryColor, $color: $white) {
  background-color: $background !important;
  color: $color !important;
  cursor: pointer;
  padding: 10px !important;
  width: 100% !important;

  &:hover {
    background-color: darken($background, 10%) !important;
  }

  &:active {
    background-color: darken($background, 20%) !important;
  }
}

@mixin buttonLink($color: $blue, $hover: darken($color, 10%), $text-decoration: underline) {
  background: none;
  border: none;
  color: $color;
  cursor: pointer;
  font-family: inherit;
  font-weight: inherit;
  padding: 0;

  &:hover {
    color: $hover;
    text-decoration: $text-decoration;
  }

  &:active {
    color: darken($hover, 10%);
  }

  &:disabled {
    color: $grey;
  }
}

@mixin modal(
  $widthLargeMonitor,
  $widthComputer: $widthLargeMonitor,
  $widthTablet: $widthComputer,
  $widthMobile: 95%
) {
  border-radius: 4px;
  background-color: $white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14);

  @media only screen and (min-width: $largeMonitorBreakpoint) {
    width: $widthLargeMonitor;
  }

  @media only screen and (min-width: $computerBreakpoint) {
    width: $widthComputer;
  }

  @media only screen and (min-width: $tabletBreakpoint) {
    width: $widthTablet;
  }

  @media only screen and (max-width: $tabletBreakpoint - 1) {
    width: $widthMobile;
  }
}

@mixin optional-at-root($sel) {
  @at-root #{if(not &, $sel, selector-append(&, $sel))} {
    @content;
  }
}

@mixin placeholder {
  @include optional-at-root("::-webkit-input-placeholder") {
    @content;
  }

  @include optional-at-root(":-moz-placeholder") {
    @content;
  }

  @include optional-at-root("::-moz-placeholder") {
    @content;
  }

  @include optional-at-root(":-ms-input-placeholder") {
    @content;
  }
}
