@import "../../theme/mixins";
@import "../../theme/variables";

.alert {
  border-radius: 2px;
  display: flex;
  font-size: 13px;
  margin-bottom: 4px;
  padding: 0.5px 8px;
  width: 100%;
}

.alert.danger {
  background-color: $red;
  color: $white;
}

.alert.info {
  background-color: rgba($blue, 0.08);
  color: $darkgrey;

  i {
    color: $blue;
  }
}

.text {
  flex: 1;
}

.removeButton {
  background: none !important;
  color: $white !important;
  font-weight: 900 !important;
  font-size: 13px !important;
  padding: 0 !important;
  margin: 0 !important;
}
