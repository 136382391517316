@import "../../theme/variables";

.channelOptionsDropdown {
  margin-left: 16px;
  margin-right: 16px;
  color: #d8d8d8;

  @media (min-width: $tabletBreakpoint) {
    display: none !important;
  }

  &.hasProjectUrls {
    @media (min-width: $tabletBreakpoint) {
      display: block !important;
    }

    @media (min-width: 1136px) {
      display: none !important;
    }

    @media (min-width: 992px) and (max-width: 1135px) {
      display: flex !important;
    }

    @media (min-width: 920px) and (max-width: 991px) {
      display: none !important;
    }
  }

  :global(.dropdown.icon) {
    display: none;
  }

  .selectedPanel {
    font-weight: 900;
    transform: none !important;
    margin-right: 0px !important;

    &:hover {
      color: $white;
    }

    @media (min-width: 992px) and (max-width: 1135px) {
      color: #4d4d4d;
      font-size: 20px;

      &:hover {
        color: $black;
      }
    }
  }

  .channelOptionsDropdownMenu {
    right: 0;
    left: auto !important;
    top: 28px !important;
    z-index: $zIndexChannelOptions !important;

    .menuItem {
      color: $black !important;
      opacity: 0.7;

      i {
        font-weight: 900;
        transform: none !important;
      }

      &:hover,
      &:global(.active) {
        opacity: 1;
      }
    }
  }
}
