@import "../../theme/mixins";
@import "../../theme/variables";

.attachments {
  padding: 7px 16px;
  background: $white;
}

:global(.sidePanel) .attachments {
  padding: 7px 10px;
}

.messageList {
  height: 100%;
  overflow-y: scroll;
  position: absolute;
  width: 100%;
  background-color: $white;
}

.messageListWrapper {
  flex: 1 1 auto;
  height: 100%;
  overflow-y: hidden;
  position: relative;
}

.resetButton {
  &:link,
  &:visited {
    display: block;
    padding: 5px;
    background: $lightgrey;
    color: $black;
    text-align: center;
  }

  &:hover,
  &:active {
    background: $grey;
    color: $black;
  }
}

.textareaSegment,
:global(.ui.form) .textareaSegment {
  position: relative;
  // flex: 0 0 65px;
  padding: 0 17px 17px 17px;

  // Hide default Dropdown trigger...
  :global(.ui.dropdown) {
    > div:global(.text) {
      display: none;
    }
  }

  textarea.textarea {
    border-width: 0;
    border-radius: 4px;
    min-height: 39px;
    max-height: 39px;
    outline: none;
    font-size: 14px;
    box-shadow: none;
    line-height: 21px;
    width: 100%;
    padding: 9px 16px;

    &:disabled {
      background-color: #f5f6f8;
    }

    &::placeholder {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &::-webkit-scrollbar {
      width: 4px;
      display: none;
    }
  }

  .messageButtons {
    padding: 4px 8px 8px;
    display: flex;
    position: relative;

    .formatting {
      display: flex;
      align-items: flex-end;
      flex-grow: 1;

      :global(.ui.basic.button) {
        padding: 3px 6px;
        color: #4d4d4d !important;
        background: transparent;
        border: 1px solid transparent;
        border-right-width: 0;
        box-shadow: none !important;
        font-size: 12px;
        height: 25px;
        text-align: center;
        vertical-align: baseline;

        &:hover,
        &:active {
          box-shadow: none !important;
        }

        > :global(.icon) {
          height: 12px;
          vertical-align: baseline;
          font-weight: 900;
          font-size: 12px;
          line-height: 14px;
        }
      }
    }

    .messageActions {
      display: flex;
      justify-content: flex-end;

      .submitBtn {
        font-size: 14px;
        line-height: 18px;
        padding: 4px 10px;

        @media (max-width: $tabletBreakpoint) {
          font-size: 12px;
        }
      }

      .discard,
      .discard:global(.ui.button:disabled, .ui.button.disabled) {
        border: 1px solid #d3d3d3;
        background: $white !important;
        color: #4d4d4d;
        margin-right: 8px;
      }
    }
  }

  small {
    display: block;
    height: 15px;
  }

  .scrollableTextarea {
    overflow: hidden;
    position: relative;
  }

  .chatboxContainer {
    max-width: $messageContainerWidth;
    border: 1px solid #d3d3d3;
    border-radius: 4px;
    margin: 0;

    &.fullWidth {
      max-width: 100%;
    }

    &.centered {
      margin: 0 auto;
    }

    &:focus-within {
      border: 1px solid #7a7a7a;
    }
  }

  &.chatTextArea {
    background-color: $white;

    .chatboxContainer {
      min-height: 75px;
      max-height: 220px;

      textarea.textarea {
        min-height: 21px;
        max-height: 80px;
        padding: 8px 16px 0;
        resize: none;
      }
    }
  }
}

.deleteModal {
  border-radius: 0px !important;
  max-width: 520px !important;

  :global(.close) {
    top: 0 !important;
    right: 0 !important;
    color: #212121 !important;
    font-weight: 900;
    font-size: 18px !important;
    line-height: 21px;
    padding-top: 18px;
    padding-right: 5px;
  }

  .modalContent {
    text-align: center;

    .heading {
      color: #212121;
      font-size: 24px;
      line-height: 30px;
      letter-spacing: -0.34px;
      margin-top: 32px;
      margin-bottom: 7px;
    }

    .content {
      font-size: 14px;
      line-height: 18px;
      color: #4d4d4d;
      letter-spacing: -0.0373333px;
    }

    .actionsContainer {
      padding-top: 6px;
      padding-bottom: 5px;

      .action {
        font-size: 14px;
        line-height: 21px;
        font-weight: normal;
        margin: 0 8px 8px;

        &.delete {
          background-color: $deleteColor;
          color: $white;
        }

        &.cancel {
          background-color: $white;
          border: 1px solid #d3d3d3;
          color: #4d4d4d;
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .textareaSegment,
  :global(.ui.form) .textareaSegment {
    padding: 0 !important;

    &.chatTextArea {
      textarea.textarea {
        border-radius: 0;
      }

      .chatboxContainer {
        border-radius: 0;
      }
    }
  }
}

:global(.ui.modal.form),
:global(.sidePanel) {
  .textareaSegment {
    .textarea {
      max-height: 62vh;
    }
  }
}

:global(.sidePanel) {
  .textareaSegment {
    margin: 10px;

    .messageButtons.activeFormatting {
      padding-bottom: 35px;
    }
  }
}

@media (max-width: $computerBreakpoint) {
  .messageButtons.activeFormatting {
    padding-bottom: 35px;
  }

  :global(.ui.modal.form) {
    .messageButtons.activeFormatting {
      padding-bottom: 35px;
    }
  }
}

.messageBoxEditHeader {
  padding-bottom: 0px !important;
}

.messageBoxEditContent {
  .textareaSegment,
  :global(.ui.form) .textareaSegment {
    textarea.textarea {
      padding: 10px !important;
    }
  }
}

:global(.ui.hidden.divider) {
  padding: 0;
  margin: 0;
}

.chatDropdown:global(.ui.scrolling.dropdown) {
  max-width: $messageContainerWidth !important;
  margin: 0;

  &.centered {
    margin: 0 auto;
  }

  &.fullWidth {
    max-width: 100% !important;
  }

  @media (max-width: $tabletBreakpoint) {
    :global(.item) {
      font-size: 10px;
    }
  }
}

:global(.sidePanel) {
  .chatDropdown:global(.ui.scrolling.dropdown .item) {
    font-size: 11px;

    @media (max-width: $tabletBreakpoint) {
      font-size: 9px;
    }
  }
}
