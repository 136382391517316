@import "../../theme/variables.scss";
@import "../../theme/mixins.scss";

.modal {
  table:global(.ui.table) {
    td + td {
      text-align: right;
    }

    :global(.icon) {
      font-weight: $fontWeightBold;
    }
  }

  kbd {
    background-color: $lightgrey;
    border: 1px solid $grey;
    border-bottom-width: 3px;
    border-radius: 4px;
    cursor: default;
    display: inline-block;
    font-size: 13px;
    line-height: 1;
    margin: 0 3px;
    padding: 6px;
    text-shadow: 2px 1px 1px $white;
    text-transform: capitalize;
    vertical-align: middle;
  }
}

.downloadLink {
  cursor: pointer;
  background-color: #685ace;
  color: #fff;
  text-shadow: none;
  font-family: "Source Sans Pro", Arial, Helvetica, sans-serif;
  margin: 0 0.25em 0 0;
  padding: 0.25em 1em 0.25em;
  font-weight: 400;
  line-height: 1em;
  font-style: normal;
  text-align: center;
  text-decoration: none;
  border-radius: 4px;
}

.downloadLink:hover {
  background-color: #4132a9;
  color: #fff;
}
