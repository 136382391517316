@import "../../theme/variables";

.avatarWrapper {
  min-height: 36px;
  min-width: 36px;
  display: block;
  position: relative;

  &:global(.left) {
    float: left;
  }

  &:global(.right) {
    float: right;
  }
}

.avatar {
  flex-shrink: 0;
  font-style: normal;
}

.icon {
  align-items: center;
  border-radius: 12px;
  border: 2px solid white;
  bottom: -4px;
  box-sizing: content-box;
  color: $white;
  display: flex !important;
  font-size: 5.5px !important;
  font-weight: 900 !important;
  height: 12px !important;
  margin: 0 !important;
  position: absolute !important;
  right: -4px;
  width: 12px !important;

  &:before {
    flex: 1;
  }

  &.star {
    background-color: $yellow;
  }

  &.check {
    background-color: $green;
  }

  &.close {
    background-color: $red;
  }
}

.circle {
  padding-top: 2px;
}
