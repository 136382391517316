@import "../../theme/variables";

:global(.ui.header) {
  &.header {
    font-weight: normal;
    font-size: 40px;
    line-height: 55px;
    margin: {
      top: 5rem;
      bottom: 0;
      left: auto;
      right: auto;
    }
    width: 65%;
  }

  &.subHeader {
    font-weight: normal;
    line-height: 26px;
    margin: {
      top: 0.5rem;
      bottom: 54px;
    }
  }

  .headerText {
    padding-left: 0 !important;
    text-align: center;
    width: 100%;

    span {
      margin-left: -25px;
    }
  }

  &.header > :global(.icon) {
    font-size: 28px;
    visibility: hidden;
    cursor: pointer;
  }

  .visibleIcon {
    visibility: visible;
  }
}

.content {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;

  &:global(.ui.grid) {
    margin: 0 !important;
  }

  &:global(.column) {
    padding: 0 !important;
  }

  :global(.ui.horizontal.list > .item) {
    &.removeLeftMargin {
      margin-left: 0;
    }
  }

  :global(.ui.input) {
    input::placeholder {
      color: #bdbdbd;
    }
  }

  :global(.ui.divider) {
    text-transform: none;
    max-width: 450px;
    padding: 0 20px;
    margin: auto;
  }

  .logo {
    background-color: #ffffff;
    display: none !important;

    @media (min-width: 480px) {
      display: block !important;
    }
  }

  .centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .fullWidth {
    width: 100%;
  }

  .section {
    padding: 0 20px;
    max-width: 450px;
    margin: 1.5em auto;

    .success {
      display: none;
    }

    &.loginLinkSent {
      background-color: rgba(104, 90, 206, 0.1);

      .success {
        display: block;
        text-align: center;
        padding: 20px;

        p {
          font-size: 14px;
        }
      }

      form {
        display: none;
      }
    }
  }

  .otherLogin {
    :global(.ui.button:hover) {
      background: none;
    }
  }

  .sidelines {
    text-align: center;
    border-bottom: 0.3vh solid #bdbdbd;
    height: 0.75em;
  }

  .sidelines span {
    display: inline-block;
    background-color: #f4f4f4;
    padding: 0 0.25em;
    color: #bdbdbd;
    font-size: 16px;
    position: relative;
    top: -4px;
  }

  p {
    font-size: 16px;
  }
}

.modal:global(.ui.modal) {
  :global(.close) {
    top: 0 !important;
    right: 0 !important;
    color: #212121 !important;
    font-size: 18px !important;
    line-height: 21px;
    padding-top: 19px;
    padding-right: 26px;
    margin-right: 0px;
  }

  :global(.header) {
    padding-bottom: 8px;
  }

  :global(.ui.header) {
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 17px;
  }

  :global(.content) {
    padding-top: 0px;
  }

  .modalDescription {
    font-size: 14px;
    margin-bottom: 12px;
  }

  .modalForm {
    :global(.fields > .field) {
      margin-top: 0;
      margin-bottom: 4px;
    }

    .formGroup {
      margin-bottom: 22px;
    }

    .actionDivider {
      margin-bottom: 12px;
    }

    .formActions {
      text-align: right;

      button {
        padding: 12px 14px;
        margin-left: 10px;
      }
    }
  }
}
