@import "../../theme/mixins";
@import "../../theme/variables";

.avatarListItem {
  background-color: $lightgrey;
  border-radius: 50%;
  cursor: pointer;
  display: inline-block;
  font-size: 11px;
  text-align: center;
  z-index: 1;

  &.anonymous {
    cursor: auto;
  }
}
