@import "../../../theme/variables";
@import "../../../theme/mixins";

.recurrenceModal {
  border-radius: 0px !important;
  max-width: 520px !important;

  :global(.close) {
    top: 0 !important;
    right: 0 !important;
    color: #212121 !important;
    font-weight: 900;
    font-size: 18px !important;
    line-height: 21px;
    padding-top: 18px;
    padding-right: 5px;
  }

  .modalContent {
    text-align: left;

    .heading {
      color: #212121;
      font-size: 24px;
      line-height: 30px;
      letter-spacing: -0.34px;
      margin-top: 0px;
      margin-bottom: 7px;
    }

    .recurrenceRadioGroup {
      margin-top: 5px;

      .recurrenceRadio {
        margin-bottom: 10px;

        &:global(.radio) {
          label {
            font-size: 16px;
            line-height: 1.5;
          }
        }
      }
    }

    .content {
      font-size: 14px;
      line-height: 18px;
      color: #4d4d4d;
      letter-spacing: -0.0373333px;
      margin: 0px;
    }

    .actionsContainer {
      padding-top: 20px;
      padding-bottom: 5px;

      .action {
        font-size: 14px;
        line-height: 21px;
        font-weight: normal;
        margin: 0 8px 8px;

        &.delete {
          background-color: $deleteColor;
          color: $white;
        }

        &.cancel {
          background-color: $white;
          border: 1px solid #d3d3d3;
          color: #4d4d4d;
        }
      }
    }
  }
}


.recurrenceConfirmModal {
  border-radius: 0px !important;
  max-width: 520px !important;

  :global(.close) {
    top: 0 !important;
    right: 0 !important;
    color: #212121 !important;
    font-weight: 900;
    font-size: 18px !important;
    line-height: 21px;
    padding-top: 18px;
    padding-right: 5px;
  }

  .modalContent {
    text-align: left;

    .heading {
      color: #212121;
      font-size: 24px;
      line-height: 30px;
      letter-spacing: -0.34px;
      margin-top: 0px;
      margin-bottom: 7px;
    }

    .recurrenceRadioGroup {
      margin-top: 5px;

      .recurrenceRadio {
        margin-bottom: 10px;

        &:global(.radio) {
          label {
            font-size: 16px;
            line-height: 1.5;
          }
        }
      }
    }

    .content {
      font-size: 14px;
      line-height: 18px;
      color: #4d4d4d;
      letter-spacing: -0.0373333px;
      margin: 0px;
    }

    .actionsContainer {
      padding-top: 20px;
      padding-bottom: 5px;

      .action {
        font-size: 14px;
        line-height: 21px;
        font-weight: normal;
        margin: 0 8px 8px;

        &.delete {
          background-color: $deleteColor;
          color: $white;
        }

        &.cancel {
          background-color: $white;
          border: 1px solid #d3d3d3;
          color: #4d4d4d;
        }
      }
    }
  }
}
