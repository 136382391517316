@import "../../theme/variables";
@import "../../theme/mixins";

.loadedContent {
  composes: loadedContent from global;
  display: flex;
  height: 100%;

  > * {
    display: flex;
    flex-direction: column;
  }
}

.container {
  flex: 1;
  display: flex !important;
  flex-direction: column;
  min-height: 80vh;
  margin-top: 20px;
  padding: 15px 0;

  h4:global(.ui.header) {
    font-size: 26px;
  }
}

.contentWrapper {
  flex: 1;
}

:global(.ui.header) {
  &.header {
    margin: 10px 0;
    font-size: 32px;

    @media (min-width: 769px) {
      margin: 30px 0;
      font-size: 48px;
    }
  }
}

.helper {
  display: block;
  margin: 10px 0;
}

.emailList {
  height: 35vh;
  overflow: auto;

  li {
    font-size: 24px;
    padding: 15px !important;
    color: gray;

    &:hover {
      color: black;
    }
  }
}

.selectAllMeetingsCheckbox {
  margin-bottom: 35px !important;
}

.sectionMemberMatch {
  cursor: pointer;
  margin: 2px 0;
  overflow: hidden;
  padding: 0 10px;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:first-of-type {
    margin-top: 0;
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  &:hover,
  &.selected {
    background: rgba($blue, 0.1);
  }
}

.sectionMemberMatchAvatar {
  display: inline-block;
  margin-right: 5px;
  vertical-align: top !important;

  i {
    line-height: 16px !important;
  }
}

.memberList {
  margin-top: 1rem;
}

.projectWrapper,
.toolsWrapper {
  input ~ label {
    font-size: 24px !important;
  }
}

.row {
  display: flex;
  flex-direction: row;
  margin-bottom: 24px;

  @media (max-width: 991px) {
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
  }
}

.column {
  flex: 50%;
  max-width: 50%;

  @media (max-width: 991px) {
    flex: 1;
    max-width: 100%;
    margin-bottom: 20px;
  }
}

.searchColumn {
  flex: 50%;
  max-width: 50%;
  padding-right: 5px;

  @media (max-width: 991px) {
    flex: 1;
    max-width: 100%;
    padding-right: 0px;
}
}

.memberColumn {
  flex: 50%;
  min-width: 60%;
  max-height: 508px;
  padding-left: 20px;
  overflow-y: auto;

  @media (max-width: 991px) {
    flex: 1;
    max-width: 100%;
    padding-left: 0;
  }
}

.filterContainer {
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (max-width: 991px) {
    margin-top: 20px;
  }
}

.sortByText {
  padding-left: 20px;
  padding-right: 10px;

  @media (max-width: 991px) {
    padding-left: 0;
    padding-right: 10px;
  }
}

.viewDropdown {
  font-size: 14px;
  min-height: 1em !important;
  min-width: 5em !important;

  &:not(:last-child) {
    margin-right: 1.5em;
  }

  &.viewDropdownFullWidth {
    width: 40%;
  }

  @media (max-width: 991px) {
      &.viewDropdownFullWidth {
      width: 50%;
    }
  }
}

.prevNext {
  margin-top: 30px;
  margin-bottom: 60px !important;

  &.peoplePage {
    position: relative;
    right: -85px;
  }

  @media (max-width: 991px) {
    button {
      width: 100% !important;
      float: none;
      margin-bottom: 15px !important;
    }
  }
}

.creating,
.done {
  text-align: center;
  padding: 60px 0 !important;

  p {
    font-size: 24px;
    margin: 30px 0 !important;
  }
}

:global(.ui.list).defaultTools {
  display: grid;
  grid-template-columns: repeat(auto-fit, 250px);
  justify-content: space-between;

  @media (max-width: $tabletBreakpoint) {
    grid-template-columns: repeat(auto-fit, 45%);
  }

  @media (max-width: 480px) {
    grid-template-columns: repeat(auto-fit, 100%);
  }

  > :global(.item) {
    padding-bottom: 20px;
    margin-left: 0;

    :global(.content) {
      font-size: 15px;
      line-height: 1.2;
      padding-left: 20px;

      > :global(.header),
      > :global(.description) {
        padding: 0 !important;
      }
    }

    i:global(.icon) {
      float: left;
      font-weight: $fontWeightHeavy;
      height: 36px !important;
      width: 36px !important;

      &:before {
        vertical-align: middle;
      }
    }
  }

  :global(.ui.toggle.checkbox label) {
    padding-left: 60px;
  }
}

:global(.ui.list).optionalTools {
  
  > :global(.item) {
    padding-top: 10px;
    padding-bottom: 10px;

      :global(.content) {
        display: flex;
        align-items: center;

        span {
          padding-right: 12px;
        }
      }
    }

  :global(.ui.toggle.checkbox input:checked) {
    ~ label:before {
      background-color: #27AE60 !important;
    }
  }
}

#newProjectModal {
  height: 90vh;

  @media (max-width: $tabletBreakpoint) {
    margin-top: 5rem !important;
  }
}

@media (max-width: $tabletBreakpoint) {
  .mainModal:global(.ui.modal) {
    margin-left: 0 !important;
    margin-right: 0 !important;

    :global(.close.icon) {
      top: -0.5rem !important;
    }

    :global(.header) {
      padding: 0 !important;
      font-size: 18px !important;
    }

    :global(.content) {
      padding-left: 0 !important;
      padding-right: 0 !important;

      :global(.item .content) {
        padding-left: 10px !important;
      }

      :global(.ui.header),
      :global(.header) {
        font-size: 16px !important;
      }

      :global(.description) {
        font-size: 14px !important;
      }

      :global(.ui.form) {
        :global(.header) {
          font-size: 16px !important;
        }

        :global(.ui.button) {
          font-size: 12px;
          padding-left: 10px;
          padding-right: 10px;
        }

        :global(.ui.input) {
          font-size: 14px;
        }

        textarea {
          font-size: 14px;
        }
      }

      .creating p {
        font-size: 20px;
      }
    }

    .prevNext {
      :global(.ui.button) {
        font-size: 13px;
      }
    }
  }
}

.lighter {
  font-weight: lighter;
}

.proposalContent {
  > p {
    font-size: 14px;
    color: $grey;
  }

  :global(.ui.input) {
      width: 100%;

      > input {
        border-radius: 2px !important;
      }
  }

}

.meetingsSubtitle {
  margin-top: -12px;
  margin-bottom: 30px;
  color: #4f4f4f;
  font-size: 14px;
  line-height: 1.25;
}

.meetingsContainer {
  display: flex;
  flex-direction: row;
  
  .meetingsColumn {
    flex: 1;
    display: flex;
    flex-direction: column;

    :global(.user) {
      font-weight: 900;
    }

    .checkboxBody {
      margin-top: -3px;
      width: 100%;
      padding-left: 54px;
    }

    .schedule {
      margin-top: 8px;
      color: #828282;
      font-size: 14px;
      line-height: 1.25;
      width: 100%;
      padding-left: 54px;
    }

    .scheduleBody {
      margin-top: 5px;
      font-size: 14px;
      line-height: 1.25;
      color: #212121;
      padding-left: 6px;
      border-left: 1px solid #c4c4c4;
    }
  }
}
