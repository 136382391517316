@import "../../theme/mixins";

.container {
  width: 600px !important;
  margin: 20px;
  margin-top: 60px;

  :global(.grid) {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    background-color: #ffffff !important;
  }

  :global(.message) {
    p {
      word-break: break-word;
    }
  }
}

.header {
  font-size: 20px !important;
  font-weight: 600;
}

.links {
  position: relative;
  top: 6px;
}

.actions {
  width: 250px;
  float: right;
  text-align: right;
}

.resubscribeLink {
  @include buttonLink();
}
